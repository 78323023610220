import React from "react";
import { useTranslation } from "react-i18next";
import { toSentenceCase, formatDate } from "../../../../helpers";
import BrowseList from "../../../BrowseList/browseList";
const EventsList = ({search, shouldReload}) => {

    const { t } = useTranslation();

    return <BrowseList
        search={search}
        getName={(item)=>item.summary}
        getDescription={(item)=>
        item.startsAt&&`${toSentenceCase(t("since"))} ${formatDate(new Date(item.startsAt),',')}h ${t("until")} ${formatDate(new Date(item.endsAt),', ')}h`
    }
        onItemCLick={(item)=>`/events/${item.id}`}
        getLinkDescription={(item)=> t("seeMeeting")}
        shouldReload={shouldReload}
        route="events"/>
}
export default EventsList;
