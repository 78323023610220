import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
    Avatar,
    Badge,
    Button,
    Card,
    Collapse,
    List,
    Modal
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
    DeleteOutlined,
    SendOutlined,
    LockOutlined, UserOutlined
} from "@ant-design/icons";
import { axios } from "../../../../axiosConfig";
import { useTranslation } from "react-i18next";
import { getEvents } from "../../../../features/calendar/calendarSlice";
import { useAppDispatch } from "../../../hooks";
import { selectUser } from "../../../../features/user/userSlice";
import { ErrorModalContext } from "../../../providers/ErrorModalProvider/errorModalProvider";
import { EventHooks } from "./events-hooks";
import { CardBreadCrumbs, EventDataDescriptions, UpdateEventDataDescriptions } from "./components";

const { Panel } = Collapse;

const EventView = ({ event, shouldReload, setShouldReload }) => {
    const { t } = useTranslation();
    // Keep track of the text in the ReactQuill
    const [notes, setNotes] = useState("");
    const [savingNotes, setSavingNotes] = useState(false);
    const [autoSaveNotes, setAutoSaveNotes] = useState(true);
    const [saveTimeoutId, setSaveTimeoutId] = useState(0);
    const [addContactLoading, setAddContactLoading] = useState(false);
    const [addEntityLoading, setAddEntityLoading] = useState(false);
    const [editingEvent, setEditingEvent] = useState(false);
    const [deletingEvent, setDeletingEvent] = useState(false);
    const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
    // 
    const [deletingEventContact, setDeletingEventContact] = useState(false);
    const [showDeleteEventContactModal, setShowDeleteEventContactModal] = useState(false);
    //
    const [deletingEventEntity, setDeletingEventEntity] = useState(false);
    const [showDeleteEventEntityModal, setShowDeleteEventEntityModal] = useState(false);


    const [sendingEvent, setSendingEvent] = useState(false);
    const [showSendEventModal, setShowSendEventModal] = useState(false);
    const [saveEventLoading, setSaveEventLoading] = useState(false);


    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formRef = useRef(null);
    const user = useSelector(selectUser);
    const showErrorModal = useContext(ErrorModalContext);


    const dateOptions = {
        hour: "numeric",
        minute: "numeric",
        day: "numeric",
        month: "numeric",
        year: "numeric"
    };

    useEffect(() => {
        event && handleNotesChange(event.notes);
    }, [event])

    const handleSubmitEvent = () => {
        formRef.current.submit();
    };

    const sendEvent = () => {
        setSendingEvent(true);
        axios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/${event.id}/send`)
            .then(() => navigate('/events'))
            .catch(error => {
                console.error('Error sending event:', error);
                showErrorModal(t("eventPage.modalErrorMessage.sendEvent"));
            })
            .finally(() => {
                setShowSendEventModal(false);
                setSendingEvent(false);
            });
    };

    const updateEvent = (updatedEvent) => {
        setSaveEventLoading(true);
        axios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/${event.id}`, updatedEvent)
            .then(() => {
                setSaveEventLoading(false);
                setEditingEvent(false);
                setShouldReload(shouldReload + 1);
            }) 
            .catch(error => {
                console.error('Error updating event:', error);
                showErrorModal(t("eventPage.modalErrorMessage.updateEvent"));
            });
    };

    const deleteEvent = () => {
        setDeletingEvent(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/${event.id}`)
            .then(() => {
                dispatch(getEvents());
                navigate('/events');
            })
            .catch(error => {
                console.error('Error deleting event:', error);
                showErrorModal('eventPage.modalErrorMessage.deleteEvent');
            })
            .finally(() => setDeletingEvent(false));
    }

    const deleteEventContact = () => {
        setDeletingEventContact(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/contact`, {
            params: {
                eventId: event.id,
                contactId: showDeleteEventContactModal.id
            }
           })
            .then(() => setShouldReload(shouldReload + 1))
            .catch(() => {
                showErrorModal(`${t("eventPage.errorMsgDeleteEventContact")}`)
            })  
            .catch(error => {
                console.error('Error deleting event entity:', error);
                showErrorModal(`${t('eventPage.errorMsgDeleteEntity')}`);
            })  
            .finally(() => {
                setShowDeleteEventContactModal(false);
                setDeletingEventContact(false)
            });
    }

    const deleteEventEntity = () => {
        setDeletingEventEntity(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/entity`, {
            params: {
                eventId: event.id,
                entityId: showDeleteEventEntityModal.id
            }
        })
            .then(() => setShouldReload(shouldReload + 1))
            .catch(() => {
                showErrorModal(`${t('eventPage.errorMsgDeleteEntity')}`)
            })
            .finally(() => {
                setShowDeleteEventEntityModal(false);
                setDeletingEventEntity(false)
            });
    }


    const onFinishEditing = (values) => {
        // Update the contact object with the form values
        const updateEventFields = {
            summary: values.summary,
            startsAt: values.startsAt,
            endsAt: values.endsAt,
            region: values.region,            
            type: values.type,
            documentUrl: values.documentUrl && !values.documentUrl.startsWith("http") ? `https://${values.documentUrl}` : values.documentUrl,
            office: values.office,
        }

        // Save the updated contact object
        updateEvent(updateEventFields);
    };

    const getEventColor = (event) => {
        return new Date(event.startsAt) > new Date() ? 'blue' : {
            "pending": 'red',
            "confirmed": 'green',
        }[event.status]
    }

    // Function to handle updating the notes in the ReactQuill
    const handleNotesChange = (value) => {
        setNotes(value);
        if (saveTimeoutId) {
            clearTimeout(saveTimeoutId);
        }
        if (autoSaveNotes) setSaveTimeoutId(setTimeout(() => {
            handleNotesSubmit(value)
        }, 1000))
    }

    // Function to handle submitting the notes to the specified endpoint
    const handleNotesSubmit = (newNotes) => {
        // Make a request to the specified endpoint using axios
        setSavingNotes(true);
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/${event.id}/notes`, { notes: newNotes })
            .then(response => {
                // Do something with the response
            })
            .catch(error => {
                // Do something with the error
            })
            .finally(() => setTimeout(() =>
                setSavingNotes(false)
                , 500));
    }

    const addContactToEventCallback = ({ contactId }) => {

        const isContactAlreadyAdded = event?.attendees?.some(attendee => attendee.id === contactId);
    
        if (isContactAlreadyAdded) {
            showErrorModal(t("eventPage.modalErrorMessage.addContactToEventCallback"));
            return;
        };
            setAddContactLoading(true);
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/contact`, { eventId: event.id, contactId })
                .then(() => setShouldReload(shouldReload + 1))
                .catch(error => {
                    showErrorModal(t("eventPage.modalErrorMessage.addContactToEventCallback"));
                })
                .finally(() => setAddContactLoading(false))
        }

    const addEntityToEventCallback = ({ entityId }) => {

        const isEntityAlreadyAdded = event?.entities?.some(entity => entity.id === entityId);

        if (isEntityAlreadyAdded) {
            showErrorModal(t("eventPage.modalErrorMessage.entityAlreadyAdd"));
            return;
        };

        setAddContactLoading(true);
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/entity`, { eventId: event.id, entityId })
            .then(() => setShouldReload(shouldReload + 1))
            .catch(error => {
                console.error('Error adding entity to event:', error);
                showErrorModal(t("eventPage.modalErrorMessage.addEntityToEventCallback"));
            })
            .finally(() => setAddEntityLoading(false))
    }

    //
    const { genNotesExtra,
        genContactsExtra,
        genEntitiesExtra,
        editEventButton
    } = EventHooks({
        autoSaveNotes,
        setAutoSaveNotes,
        handleNotesSubmit,
        savingNotes,
        addContactToEventCallback,
        addEntityToEventCallback,
        editingEvent,
        setEditingEvent,
        setShowDeleteEventModal,
        saveEventLoading,
        handleSubmitEvent
    });

    return (
        <>
            <CardBreadCrumbs event={event} />
            <div style={{ padding: '2em' }}>
                <Badge.Ribbon text={new Date(event.startsAt) > new Date() ? t(`calendar.status.not_started`) : t(`calendar.status.${event.status}`)} color={getEventColor(event)}>
                    <Card title={event.summary}>
                        {editingEvent ?
                            <UpdateEventDataDescriptions
                                editEventButton={editEventButton}
                                onFinishEditing={onFinishEditing}
                                formRef={formRef}
                                event={event} />
                            :
                            <EventDataDescriptions editEventButton={editEventButton}
                                event={event} />}
                        <Collapse>
                            <Panel header={t("eventPage.Panelheader.notes")} key="1" style={{ padding: "0" }} extra={genNotesExtra()} collapsible={false}>
                                <div style={{ paddingBottom: "3em" }}>
                                    {/* Pass the handleNotesChange function to the ReactQuill's onChange event */}
                                    <ReactQuill theme="snow" style={{ height: '15em' }} placeholder={`${t("eventPage.placeholderNotes")}`} value={notes} onChange={handleNotesChange} />
                                </div>
                            </Panel>
                            <Panel header={t("eventPage.Panelheader.assistants")} key="2" extra={genContactsExtra()}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={event?.attendees}
                                    renderItem={(item, idx) => (
                                        <List.Item
                                            key={idx}
                                            style={{ paddingLeft: '1.5em', paddingRight: '1em' }}
                                            actions={[
                                                <Button onClick={() => setShowDeleteEventContactModal(item)} type="default"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "red" }} shape="circle" icon={<DeleteOutlined />} />
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={(item.picture && <Avatar src={item?.picture} />) || <Avatar icon={<UserOutlined />} />}
                                                title={<Link to={`/contacts/${item?.id}`}>{item?.name ? `${item?.name} ${item?.surname ?? ''}` : item?.email}</Link>}
                                                description={<div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {item?.email && item?.email.includes('.fake') ? null : item?.email}
                                                    {user.role !== 'volunteer' && item?.positions.filter(position => !position?.endsAt).map(position => (
                                                        <> | {position?.title} {t("in")} <Link to={`/entities/${position?.entity?.id}`}>{position?.entity?.name}</Link>
                                                        </>
                                                    ))}
                                                </div>}
                                            />
                                        </List.Item>
                                    )}
                                /> 
                            </Panel>
                            <Panel header={t("eventPage.Panelheader.organizations")} key="3" extra={genEntitiesExtra()}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={event.entities}
                                    renderItem={(item, idx) => (
                                        <List.Item
                                            key={idx}
                                            style={{ paddingLeft: '1.5em', paddingRight: '1em' }}
                                            actions={[
                                                <Button onClick={() => setShowDeleteEventEntityModal(item)} type="default"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "red" }} shape="circle" icon={<DeleteOutlined />} />
                                            ]} >
                                             <List.Item.Meta
                                                title={<Link className={user?.role === 'volunteer' ? `linkEntity` : ''} to={`/entities/${item.id}`}>
                                                           {item.name}
                                                       </Link>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Panel>
                        </Collapse>
                        <div style={{ display: 'flex', paddingTop: '1em', justifyContent: 'center', width: '100%' }}>
                            {event.status === 'pending' && <Button disabled={new Date(event.startsAt) > new Date()} type="primary" onClick={() => setShowSendEventModal(true)}>{user.role === 'volunteer' ? <><SendOutlined rotate={-90} />&nbsp;{t("eventPage.btnCloseEvent.isVolunteer")}</> : <><LockOutlined />&nbsp;{t("eventPage.btnCloseEvent.isAdmin")}</>}</Button>}
                        </div>
                    </Card>
                </Badge.Ribbon>
            </div>
            <Modal
                title={t("eventPage.modalDeleteEvent.title")}
                open={showDeleteEventModal}
                onOk={deleteEvent}
                confirmLoading={deletingEvent}
                onCancel={() => setShowDeleteEventModal(false)}
            >
                {t("eventPage.modalDeleteEvent.body")}
            </Modal>
            <Modal
                title={t("eventPage.modalDeleteEntity.title")}
                open={showDeleteEventEntityModal}
                onOk={deleteEventEntity}
                confirmLoading={deletingEventEntity}
                onCancel={() => setShowDeleteEventEntityModal(false)}
            >
                {t("eventPage.modalDeleteEntity.body")}
            </Modal>
            <Modal
                title={`${t("eventPage.modalDeleteAssistantToEvent.title1")} ${showDeleteEventContactModal.name} ${showDeleteEventContactModal.surname ?? ''} ${t("eventPage.modalDeleteAssistantToEvent.title2")}`}
                open={showDeleteEventContactModal}
                onOk={deleteEventContact}
                confirmLoading={deletingEventContact}
                onCancel={() => setShowDeleteEventContactModal(false)}
            >
                {t("eventPage.modalDeleteAssistantToEvent.body")}
            </Modal>
            <Modal
                title="¿Estás seguro de que quieres actualizar el estado del evento?"
                open={showSendEventModal}
                onOk={sendEvent}
                confirmLoading={sendingEvent}
                onCancel={() => setShowSendEventModal(false)}
            >
                {user.role === 'volunteer' ? t("eventPage.modalUpdateEvent.bodyIsVolunteer") : t("eventPage.modalUpdateEvent.bodyIsAdmin")}
            </Modal>
        </>
    )

}

export default EventView;
