import { Button, DatePicker, Form, Input, Modal, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from 'react';
import moment from "moment";
import SearchInput from "../../../searchInput/searchInput";
import Title from "antd/es/typography/Title";
import { axios } from "../../../../../axiosConfig";
import { useTranslation } from "react-i18next";
import CreateEntityForm from "../../entitiesList/forms/createEntity.form";
import { AddNewEmail } from "../components";
import './creteInteraction.form.css';

const CreatePositionForm = ({ onFinish, contact, initialValues, includesEndsCalendar, setIncludesEndsCalendar }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);
    const [createdEntity, setCreatedEntity] = useState(null);
    const { t } = useTranslation();
    const [valueEndsAt, setValueEndsAt] = useState(false);


    const [tags, setTags] = useState(initialValues?.email ? initialValues.email.split(',').map(email => email.toLowerCase().trim()) : []);
    const [emailErr, setEmailErr] = useState(false);

    // define the form layout and validation rules
    const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
        style: {
            display: "flex",
            flexDirection: "column",
            gap: "1em"
        }
    };
    const validateMessages = {
        required: '${label} es obligatorio rellenarlo',
        types: {
            email: 'No es un correo electrónico válido',
            number: '${label} debe ser un número',
        },
    };

    const createPosition = (values) => {

        if(arrEmailsJoined){

        // Set the isSubmitting flag to true
        setIsSubmitting(true);

        // Submit the form values to the /v1/events route
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/positions`, {
            ...values,
            contactId: contact.id,
            email: arrEmailsJoined
        })
            .then(() => {
                // Set the isSubmitting flag to false and call the onFinish callback
                setIsSubmitting(false);
                onFinish(values);
                window.location.replace(`/contacts/${contact.id}`);
            });
       }else{
        setEmailErr(true);
       };

    };

    const updatePosition = (values) => {

        if(arrEmailsJoined){

        // Set the isSubmitting flag to true
        setIsSubmitting(true);

        // Submit the form values to the /v1/events route
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/positions/${initialValues.id}`, {
            ...values,
            contactId: contact.id,
            email: arrEmailsJoined
        })
            .then(() => {
                // Set the isSubmitting flag to false and call the onFinish callback
                setIsSubmitting(false);
                onFinish(values);
                window.location.replace(`/contacts/${contact.id}`);
            });

        }else{
            setEmailErr(true);
           };
    };


    let startsDate = "";
    if (initialValues && initialValues.startsAt) {
        startsDate = initialValues.startsAt;
    } else {
        startsDate = moment().format("DD-MM-YYYY");
    };

    let endsDate = "";
    if (initialValues && initialValues.endsAt) {
        endsDate = initialValues.endsAt;
    }


    const handleChangeEndsAt = (date) => {
        setValueEndsAt(true)
    };

    useEffect(() => {
        if (initialValues && initialValues.endsAt) {
            setValueEndsAt(true);
        };
    }, [initialValues]);

    const showEndsCalendar = (e) => {
        e.preventDefault();
        setValueEndsAt(true);
        setIncludesEndsCalendar(true);
    };

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    const ForMap = ({ tag,  setTags, tags }) => {
        const [inputVisible, setInputVisible] = React.useState(false);
        const [inputValue, setInputValue] = React.useState('');
        const inputRef = React.useRef(null);
        
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };

        const showInput = () => {
            setInputVisible(true);
        };
        
        const handleInputChange = (e) => {
            setInputValue(e.target.value.toLowerCase().trim());
        };
        
        const handleInputConfirm = (e) => {
            if (inputValue && tags.indexOf(tag) !== -1) {
                setTags(tags.map(t => (t === tag ? inputValue : t)));
            }
            setInputVisible(false);
            setInputValue('');
        };

    
        React.useEffect(() => {
            if (inputVisible) {
                inputRef.current?.focus();
            }
        }, [inputVisible]);

        
        const tagElem = (
            <Tag closable
            onClose={(e) => {
                e.preventDefault();
                handleClose(tag);
            }}>
            {tag}
            </Tag>
        );

        let widthInput = inputValue.length * 4 + 80;

        return (
            <>
                {inputVisible ? (
                <Input
                    className="TagEmailInput"
                    style={{width: `${widthInput}px`}}
                    ref={inputRef}
                    placeholder={tag}
                    type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                        onKeyDown={handleKeyPress}
                    />
                    ) : (
                    <span
                        key={tag}
                        onClick={showInput}

                        style={{
                            display: 'inline-block',
                            margin: '1px 0px',
                            cursor: 'pointer'

                        }}
                        >
                        {tagElem}
                    </span>
                )}
            </>
        );
    };


    const tagChild = tags.map((tag) => (
        <ForMap
            key={tag}
            tag={tag}
            setTags={setTags}
            tags={tags}
        />
    ));

    const arrEmails = tagChild.map(i => i.key)
    const arrEmailsJoined = arrEmails.join(', ');
        
    const asterisk = () => {
        return (
            <>
                <span style={{color: 'red'}}>
                    * 
                </span>
                &nbsp;
            </>
        )
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return <>
        <Form  onFinish={initialValues ? updatePosition : createPosition} {...formLayout} validateMessages={validateMessages} initialValues={initialValues && updatePosition ?
            { ...initialValues, startsAt: '', endsAt: '' } : initialValues} 
            onKeyDown={handleKeyPress} 
            >
            <div style={{ width: "100%", textAlign: "center" }}><Title level={3}>{initialValues ? t("contacts.update_position_title") : t("contacts.create_position_title")}</Title></div>
            <Form.Item label={t("contacts.forms.position.entity")}
                name="entityId"
                rules={[{ required: true }]}>
                {createdEntity ? <SearchInput url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities`} createButtonName="Crear entidad"
                    createButtonCallback={() => setShowCreateEntityModal(true)} initialValue={{ value: createdEntity.id, text: createdEntity.name }} /> :
                    <SearchInput url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities`} createButtonName="Crear entidad"
                        createButtonCallback={() => setShowCreateEntityModal(true)} initialValue={{ value: initialValues?.entity?.id, text: initialValues?.entity?.name }} />}
            </Form.Item>
            <Form.Item
                label={t("contacts.forms.position.title")}
                name="title"
                rules={[{ required: true, type: 'string' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label={<>{asterisk()}{t("contacts.forms.position.email")}</>} rules={[{ required: true, type: 'email' }]}>
                <Tooltip title={t("contacts.notKnowEmail")}>
                    <div className="form_email">
                        <div >
                            {tagChild}
                        </div>
                        <AddNewEmail
                            setTags={setTags}
                            tags={tags}
                        />
                    </div>
                </Tooltip>
                {emailErr && <span style={{color: 'red'}}>{t("contacts.forms.position.emailValidation")}</span>}
                <Input style={{display: 'none'}} readonly value={arrEmailsJoined}  />
            </Form.Item>
            <Form.Item
                label={t("contacts.forms.position.address")}
                name="address"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("contacts.forms.position.zip_code")}
                name="zipCode"
            >
                <Input />
            </Form.Item>
            <Form.Item label={t("contacts.forms.position.phone")} name="phone">
                <Input onInput={(e) => {
                    e.preventDefault();
                    const value = e.target.value.replace(/[^\d+]/g, '');
                    e.target.value = value;
                }} />
            </Form.Item>
            <Form.Item
                label={t("contacts.forms.position.political_party")}
                name="political_party"
            >
                <Input />
            </Form.Item>
            <Form.Item
                rules={[{ required: true }]}
                label={t("contacts.forms.position.starts_at")}
                name={"startsAt"}
            >
                <DatePicker
                    format="DD-MM-YYYY" style={{ minWidth: "100%" }}
                    placeholder={moment(startsDate).format("DD-MM-YYYY")}
                />
            </Form.Item>
            <Button hidden={includesEndsCalendar} style={{ width: '40%', margin: '0 auto', display: 'block' }} onClick={showEndsCalendar}>{t("entitiesPage.addEndDate")}</Button>
                {includesEndsCalendar && <Form.Item
                    label={t("contacts.forms.position.ends_at")}
                    name={valueEndsAt ? "endsAt" : undefined}
                    rules={[{ required: true }]}
                >
                <DatePicker format="DD-MM-YYYY" style={{ minWidth: "100%" }}
                    onChange={handleChangeEndsAt}
                    placeholder={moment(endsDate).format("DD-MM-YYYY")}
                />
            </Form.Item>}
            <Form.Item style={{ paddingBottom: "0em", display: "flex", justifyContent: "center", paddingTop: "1em" }}>
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                    {initialValues ? t("contacts.update_position_button") : t("contacts.create_position_button")}
                </Button>
            </Form.Item>
        </Form>
        <Modal footer={null} open={showCreateEntityModal && !createdEntity} onCancel={() => setShowCreateEntityModal(false)} title={t("entitiesPage.btnCreateEntity")}>
            <CreateEntityForm onFinish={(entity) => setCreatedEntity(entity)} />
        </Modal>
    </>
}


export default CreatePositionForm;

