import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { axios } from "../../../../axiosConfig";
import { useParams } from "react-router-dom";
import InteractionView from "./interaction.view";
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import './interaction.styles.css';


const Interaction = () => {

    const {t} = useTranslation();
    const params = useParams();
    const interactionId = params['interactionId'];
    const [interaction, setInteraction] = useState();
    const [shouldReload, setShouldReload] = useState(0);
    const [Error, setError] = useState("");
    
    function error() {
        Modal.error({
          title: `${t('interactions.modal.title')}`,
          content: `${t('interactions.modal.body')}`,
          onOk: () => {
            window.location.href = "/dashboard"; 
         }
        });
      };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interactionId}`)
            .then(({ data }) => {
                setInteraction(data);
            })
            .catch(error => {
                console.error("Interaction error", error);
                setError("Error");   
             });
    },
        [interactionId]);
        
    useEffect(() => {
        if(Error || Error === undefined) { 
            const errorButton = document.getElementById('error');
            if (errorButton) {
                errorButton.click();
                setError('');
            };
        };
    }, [Error]);

    const reloadCallback = (interactionId) => {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interactionId}`)
                .then(({data}) => setInteraction(data))
                .catch(error => {
                    // handle error
                    console.error(error);
                });
        };

    return interaction ? <InteractionView interaction={interaction && interaction} reloadCallback={reloadCallback} /> :
        Error || Error === undefined ?
        <div className="error-message">
         <Button id="error" onClick={error}>Error</Button>
        </div>
            :
            <div className="spinner">
                <LoadingOutlined
                    style={{
                        fontSize: 52,
                    }}
                    spin
                />
            </div>

}

export default Interaction;
