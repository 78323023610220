import { Descriptions, Form, Input } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import { useTranslation } from "react-i18next";



export const UpdateContactForm = ({contact, formRef, onFinishEditing, editContactButton}) => {

    const { t } = useTranslation();

        return (
            <Form initialValues={contact} ref={formRef} onFinish={onFinishEditing}>
                <Descriptions title={t("contacts.forms.update.subtitle")} extra={editContactButton()}>
                    <DescriptionsItem label={t("contacts.forms.update.name")}>
                        <Form.Item name="name">
                            <Input />
                        </Form.Item>
                    </DescriptionsItem>
                    <DescriptionsItem label={t("contacts.forms.update.surname")}>
                        <Form.Item name="surname">
                            <Input />
                        </Form.Item>
                    </DescriptionsItem>
                    <DescriptionsItem label={t("contacts.forms.update.address")}>
                        <Form.Item name="address">
                            <Input />
                        </Form.Item>
                    </DescriptionsItem>
                    <DescriptionsItem label={t("contacts.forms.update.zip_code")}>
                        <Form.Item name="zipCode">
                            <Input />
                        </Form.Item>
                    </DescriptionsItem>
                    <DescriptionsItem label={t("contacts.forms.update.phone")}>
                        <Form.Item name="phone">
                            <Input onInput={(e) => {
                                e.preventDefault();
                                const value = e.target.value.replace(/[^\d+]/g, '');
                                e.target.value = value;
                            }} />
                        </Form.Item>
                    </DescriptionsItem>
                    <DescriptionsItem label={t("contacts.forms.update.email")}>
                        <Form.Item name="email">
                            <Input />
                        </Form.Item>
                    </DescriptionsItem>
                </Descriptions>
            </Form>
        );
    }