import React from "react";
import { useParams } from "react-router-dom";
import { Card, Modal, Descriptions, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { InteractionHooks } from "./hooks";
import { CardBredCrumbs, CardTitle, UpdateInteractionForm } from "./components";


const InteractionView = ({ interaction, reloadCallback }) => {

    const {t} = useTranslation();
    const formRef = React.useRef(null);
    const params = useParams();

    const [isEditing, setIsEditing] = React.useState(false);
    const [showDeleteInteractionModal, setShowDeleteInteractionModal] = React.useState(false);
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [isDeletingInteraction, setIsDeletingInteraction] = React.useState(false);
    const [shouldReload, setShouldReload] = React.useState(0);


    const handleSubmitInteraction = () => {
        formRef.current.submit();
        reloadCallback(params.interactionId);
        setIsEditing(false);
    };


    const initialValues = {
        type: interaction.type,
        contact1: interaction.contact1.name,
        contact2: interaction.contact2.name,
        contact2Id: interaction.contact2Id,
        notes: interaction.notes
    };

    const {editButtons, deleteInteractionCallback} = InteractionHooks({
                                            isEditing, 
                                            setIsEditing, 
                                            setShowDeleteInteractionModal, 
                                            saveLoading, 
                                            interaction,
                                            handleSubmitInteraction,
                                            setIsDeletingInteraction
                                        });


    return (
        interaction &&
        <>
                <Modal
                    title={t('interactions.modalInteraction.title')}
                    open={showDeleteInteractionModal}
                    onOk={deleteInteractionCallback}
                    confirmLoading={isDeletingInteraction}
                    onCancel={() => setShowDeleteInteractionModal(false)}
                >
                   {t('interactions.modalInteraction.body')}
                </Modal>
            <CardBredCrumbs interaction={interaction} />
            <Card title={
            <CardTitle 
                interaction={interaction}
                formRef={formRef}
                editButtons={editButtons}
                 />  
                }>
                {!isEditing
                    ?
                    <Descriptions>
                        <Descriptions.Item label={t("interactions.updateInteraction.type")}>{`${interaction.type === 'phone' ? 'Llamada' : 'Conversación'}`}</Descriptions.Item>
                        <Descriptions.Item
                            label={t("interactions.updateInteraction.contact1")}>{`${interaction.contact1.name} ${interaction.contact1.surname ?? ''}`}</Descriptions.Item>
                        <Descriptions.Item
                            label={t("interactions.updateInteraction.contact2")}>{`${interaction.contact2.name} ${interaction.contact2.surname ?? ''}`}</Descriptions.Item>
                        <Descriptions.Item label={t("interactions.updateInteraction.notes")}>{interaction.notes !== "" ? interaction.notes : <Tag color="blue"> {t("interactions.updateInteraction.noNotes")}</Tag>}</Descriptions.Item>
                    </Descriptions>                        

                    :
                   <UpdateInteractionForm 
                                    initialValues={initialValues}
                                    interaction={interaction}
                                    setShouldReload={setShouldReload}
                                    shouldReload={shouldReload}
                                     />
                }
            </Card>
        </>

    )
}

export default InteractionView;
