import React from 'react';
import '../documents.css';
import propTypes from 'prop-types';

function AppBreadcrumb({ changeFolder, breadcrumbItems }) {
  return (
    <div>
      <nav aria-label="Breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <li key={item.folder} className="breadcrumb-item">
              <button type='button' onClick={() => changeFolder(item)}>
                <span className="go">{item.label}</span>
              </button>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

AppBreadcrumb.propTypes = {
  changeFolder: propTypes.func.isRequired,
  breadcrumbItems: propTypes.array
}

export default AppBreadcrumb;
