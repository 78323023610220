import axios from 'axios';
import {authError} from "./features/user/userSlice";

// Attach the token to the authorization header of each request
const attachToken = (config) => {
    // Get the token from local storage
    const token = window.localStorage.getItem('googleAuthToken');

    // If there is a token, attach it to the authorization header of the request
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    };

    // Return the updated config
    return config; 
};

const checkTimeCurrentToken = (config) => {
    // Get the token from local storage
    const googleToken = window.localStorage.getItem('checkTimeCurrentToken');

    if ( Date.now() - googleToken > 1800000) {
        // Dispatch a "logoutUser" action
        config.dispatch(authError());
    }else{
        // execute function update token
        window.localStorage.setItem('checkTimeCurrentToken', Date.now());
    };

    // Return the updated config
    return config; 
};

export const attachInterceptors = (store) => {
    axios.interceptors.response.use(
        response => {
            // Do something with the response data
            return response;
        },
        error => {

            // Handle the error
            if (error.response && error.response.status === 401 ) {
                // Dispatch a "logoutUser" action
                store.dispatch(authError());
            }   

            return Promise.reject(error);
        }
    );
}

// Add the attachToken function as a middleware to axios
axios.interceptors.request.use(attachToken);
axios.interceptors.request.use(checkTimeCurrentToken);

// Export the axios instance that has the middleware attached
export {axios};
