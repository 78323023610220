import React from "react";
import BrowseList from "../../../BrowseList/browseList";
import { useTranslation } from "react-i18next";
import {CalendarOutlined, ClockCircleOutlined, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {formatDate} from "../../../../helpers/date";
import {Tag, Button} from "antd";
import {Link} from "react-router-dom";

const InteractionsList = ({search, shouldReload}) => {

    const {t} = useTranslation();

    const getInteractionName = (type) => {
        return {
            'phone':<span>{t("interactions.list.call")}</span>, 
            'in_person': <span>{t("interactions.list.in_person")}</span>
        }[type]
    }

    const getInteractionIcon = (type) => {
        return {
            'phone':<PhoneOutlined/>,
            'in_person': <WhatsAppOutlined/>
        }[type]
    }

   
return <BrowseList
    search={search}
    getIcon={(item)=>getInteractionIcon(item.type)}
    getName={(item)=><Link to={`/interactions/${item.id}`}>{getInteractionName(item.type)}</Link>}
    getDescription={(item)=>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {`${item.creator.name} ${item.creator.surname ?? ''} -> ${item.contact2.name} ${item.contact2.surname ?? ''}`} 
        </span>
        <Tag style={{ cursor: 'auto' }} icon={<ClockCircleOutlined />} color="default">{formatDate(item.startsAt)} </Tag>
        </div>
    }
    onItemCLick={()=>null}
    getLinkDescription={()=>null}
    shouldReload={shouldReload}
    route="call-interactions"/>
}
export default InteractionsList;
