import {
    Avatar,
    Badge,
    Button,
    Card,
    Divider, Empty, Modal
} from "antd";
import {  useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { axios } from "../../../../axiosConfig";
import CreatePositionForm from "./forms/createPosition.form";
import { useTranslation } from "react-i18next";
import InteractionsSection from "./sections/interactions.section";
import SuggestionsSection from "./sections/suggestions/suggestions.section";
import { NotesSection } from "./sections/notes/notes.section";
import { ContactHooks } from "./hooks";
import { CardBreadCrumb, ContactInfoDescriptions, EditPositionButton, GeneratePositionsList, UpdateContactForm } from "./components";
import './contact.css';

const ContactView = ({ contact, shouldReload, setShouldReload }) => {
    const { t } = useTranslation();

    const [notes, setNotes] = useState("");
    const [savingNotes, setSavingNotes] = useState(false);
    const [autoSaveNotes, setAutoSaveNotes] = useState(true);
    const [saveTimeoutId, setSaveTimeoutId] = useState(0);

    const [editingContact, setEditingContact] = useState(false);
    const [saveConctactLoading, setSaveContactLoading] = useState(false);
    const [savePositionLoading, setSavePositionLoading] = useState(false);
    const [showCreatePositionModal, setShowCreatePositionModal] = useState(false);
    const [showUpdatePositionModal, setShowUpdatePositionModal] = useState(false);
    const [editingPosition, setEditingPosition] = useState(false);
    const [deletingContact, setDeletingContact] = useState(false);
    const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
    const [createPositionParam, setCreatePositionParam] = useState(null);

    const formRef = useRef(null);
    const navigate = useNavigate();
    
    const [includesEndsCalendar, setIncludesEndsCalendar] = useState(false);


    // Inline useQuery
    const query = new URLSearchParams(useLocation().search);
    const createPosition = query.get('createPosition');

    useEffect(() => {
        if (createPosition !== createPositionParam) {
            setCreatePositionParam(createPosition);
            setShowCreatePositionModal(!!createPosition);
        }
    }, [createPosition]);


    const handleSubmitContact = () => {
        formRef.current.submit();
    };

    const dateOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric"
    };

    const deletePosition = (position) => {
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/positions/${position.id}`)
            .then(() => setShouldReload(shouldReload + 1))
    };

    const deleteContact = () => {
        const hasPositions = contact.positions && contact.positions.length > 0;

        if (hasPositions) {
            Modal.warning({
                title: t("contacts.modalDeleteContact.title"),
                content: t("contacts.modalDeleteContact.content"),
                onOk: () => {
                    setShowDeleteContactModal(false)
                }
            }); 
        } else {
            setDeletingContact(true)
            try {
                axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts/${contact.id}`)
                    .then(() => navigate('/contacts'))
                    .finally(() => setDeletingContact(false))
            } catch (error) {
                console.error(error)
            };
        }
    };

    const onFinishEditing = (values) => {
        // Update the contact object with the form values
        contact.name = values.name;
        contact.surname = values.surname;
        contact.address = values.address;
        contact.zipCode = values.zipCode;
        contact.phone = values.phone;
        contact.email = values.email;

        // Save the updated contact object
        updateContact(contact);
    };

    const updateContact = (updatedContact) => {
        setSaveContactLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts/${contact.id}`, updatedContact)
            .then()
            .catch()
            .finally(() => {
                setSaveContactLoading(false);
                setEditingContact(false);
                setShouldReload(shouldReload + 1);
            });
    };

    const lastUpdatedDifference = (pastDate) => {
        // Get the current time in milliseconds
        const currentTime = new Date().getTime();


        // Get the number of milliseconds since the Unix epoch for the past date
        const pastTime = pastDate.getTime();

        // Calculate the difference in milliseconds
        const timeDifference = currentTime - pastTime;

        // Convert the difference in milliseconds to days by dividing by the number of milliseconds in a day
        return Math.round(timeDifference / (1000 * 60 * 60 * 24));
    }


    // Function to handle updating the notes in the ReactQuill
    const handleNotesChange = (value) => {
        setNotes(value);
        if (saveTimeoutId) {
            clearTimeout(saveTimeoutId);
        }
        if (autoSaveNotes) setSaveTimeoutId(setTimeout(() => {
            handleNotesSubmit(value)
        }, 1000))
    }

    // Function to handle submitting the notes to the specified endpoint
    const handleNotesSubmit = (newNotes) => {
        // Make a request to the specified endpoint using axios
        setSavingNotes(true);
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts/${contact.id}/notes`, { notes: newNotes })
            .then(response => {
                // Do something with the response
            })
            .catch(error => {
                // Do something with the error
            })
            .finally(() => setTimeout(() =>
                setSavingNotes(false)
                , 500));
    };


    //
    const [showModal, setShowModal] = useState({
        createInteraction: false,
        updateInteraction: false
    });

    const {editContactButton, showFromInteraction} = ContactHooks({
                                             editingContact,
                                             setEditingContact,
                                             setShowDeleteContactModal, 
                                             saveConctactLoading, 
                                             handleSubmitContact,
                                             showModal, 
                                             setShowModal});


 useEffect(() => {
     contact && handleNotesChange(contact.notes);
   }, [contact]);


    return <>
       <CardBreadCrumb contact={contact} />
        <div style={{ padding: '2em' }}>
            <Badge.Ribbon text={<>{t("contacts.flag.update")} {lastUpdatedDifference(new Date(contact.updatedAt))} {t("contacts.flag.days")}</>}>
                <Card title={<div style={{ display: 'flex', gap: '1em', alignItems: "center" }}>{contact.picture && <Avatar size="large" src={contact.picture} className="header-menu-avatar" />}
                    {contact.name} {contact.surname ?? ''}</div>}>
                    {editingContact ? 
                    <UpdateContactForm
                        contact={contact}
                        editContactButton={editContactButton}
                        formRef={formRef}
                        onFinishEditing={onFinishEditing}
                       /> 
                    : 
                    <ContactInfoDescriptions 
                    contact={contact}
                    editContactButton={editContactButton}
                    />
                    }
                    <Divider>{t("contacts.positions")}</Divider>
                    {contact?.positions?.length > 0 &&   <EditPositionButton 
                                                       editingPosition={editingPosition}
                                                       setEditingPosition={setEditingPosition}
                                                       savePositionLoading={savePositionLoading}
                                                       saveConctactLoading={saveConctactLoading}
                                                       setIncludesEndsCalendar={setIncludesEndsCalendar}
                                                       setShowCreatePositionModal={setShowCreatePositionModal}
                                                       /> }
                    {contact?.positions?.length > 0 ? 
                                                  <GeneratePositionsList
                                                contact={contact}
                                                deletePosition={deletePosition}
                                                editingPosition={editingPosition}
                                                savePositionLoading={savePositionLoading}
                                                setShowUpdatePositionModal={setShowUpdatePositionModal}
                                                />
                                                 : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("contacts.no_positions")}>
                        <Button type="default" onClick={() => setShowCreatePositionModal(true)}>{t("contacts.add_positions")}</Button>
                    </Empty>}
                    <NotesSection notes={notes} handleNotesChange={handleNotesChange} />
                    {showFromInteraction()}
                    <InteractionsSection contact={contact} handleNotesChange={handleNotesChange} showModal={showModal} setShowModal={setShowModal} />
                    <SuggestionsSection contact={contact} />
                </Card>
            </Badge.Ribbon>
        </div>
        <Modal
            footer={null}
            open={showCreatePositionModal}
            onCancel={() => setShowCreatePositionModal(false)}>
            <CreatePositionForm onFinish={() => {
                setShowCreatePositionModal(false);
                setShouldReload(shouldReload + 1);
            }} contact={contact}
                includesEndsCalendar={includesEndsCalendar}
                setIncludesEndsCalendar={setIncludesEndsCalendar}
            />
        </Modal>
        <Modal
            footer={null}
            open={showUpdatePositionModal}
            onCancel={() => setShowUpdatePositionModal(false)}>
            <CreatePositionForm onFinish={() => {
                setShouldReload(shouldReload + 1);
                setShowUpdatePositionModal(false);
            }} contact={contact} initialValues={showUpdatePositionModal}
                includesEndsCalendar={includesEndsCalendar}
                setIncludesEndsCalendar={setIncludesEndsCalendar}
            />
        </Modal>
        <Modal
            title={t("contacts.delete_contact_modal_title")}
            open={showDeleteContactModal}
            onOk={deleteContact}
            confirmLoading={deletingContact}
            onCancel={() => setShowDeleteContactModal(false)}
        >
            {t("contacts.delete_contact_modal_description")}
        </Modal>
    </>
}

export default ContactView;
