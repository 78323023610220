import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { loginUser, selectLoginStatus, selectUser } from "../../../../features/user/userSlice";
import { auth } from "../../../../features/user/api/firebase";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd/es";
import { GoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DOMPurify from 'dompurify';
import './css/responsive.css';
import ils_22 from "./ils_22.svg";
import './css/style.css';
import './login.css';

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const loginStatus = useSelector(selectLoginStatus);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

   

    useEffect(() => {
        user && navigate("/dashboard")
    }, [user])

    useEffect(() => {
        const googleToken = window.localStorage.getItem('googleAuthToken');
        if (googleToken) dispatch(loginUser(googleToken));
    }, [])

    auth.onAuthStateChanged(async (authUser) => {
        if (authUser) {
        }
    })






    return (
        <Layout>
            <Layout.Content>
                <div className="user-data-page clearfix d-md-flex">
                    <div className="illustration-wrapper d-none d-md-flex align-items-center">
                        <h3 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t("login.descriptionPage")) }} />

                        <div className="illustration-holder">
                            <img src={ils_22} alt="image" className="illustration m-auto" />
                        </div>
                    </div>

                    <div className="form-wrapper">
                        <form action="#" className="user-data-form lg-mt-40 h-100">
                            <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t("login.welcomeMsg")) }} />
                                  <p className="header-info pt-20 pb-20 lg-pt-10 lg-pb-10" 
                                   dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t("login.descriptionTool")) }} />
                            <div className="row" style={{ display: "flex", flexDirection: "column", height: "70%", justifyContent: "space-between" }}>
                                <div></div>
                                <div className="col-12" style={{ display: "flex", width: '100%', justifyContent: "center" }}>
                                    <GoogleLogin
                                        size="large"
                                        onSuccess={credentialResponse => {
                                            dispatch(loginUser(credentialResponse.credential))
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <p className="text-center copyright-text m0">Copyright @2022 Databay Solutions S.C.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout.Content>
            {/*<Layout.Footer></Layout.Footer>*/}
        </Layout>
    );
}
export default Login;
