import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import {useParams, Navigate} from "react-router-dom";
import { selectUser } from "../../../../features/user/userSlice";
import { LoadingOutlined } from '@ant-design/icons';
import {axios} from "../../../../axiosConfig";
import EntityView from "./entity.view";
import { Modal, Button } from 'antd';
import './entity.styles.css';

const Entity = () => {

    const user = useSelector(selectUser);

    const {t} = useTranslation();
    const params = useParams();
    const entityId = params['entityId'];
    const [entity,setEntity] = useState();
    const [Error, setError] = useState("");

    function error() {
        Modal.error({
            title: `${t('entity.modal.title')}`,
            content: `${t('entity.modal.body')}`,
          onOk: () => {
            window.location.href = "/dashboard"; 
        }
        });
      };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities/${entityId}`)
            .then(({data}) => {
                setEntity(data.data);
                if (!data.data) {
                    setError("Error");
                };
            })
            .catch(error => {
                console.error("Entity error", error);
                setError(error.response?.data?.message);
            });
    }, [entityId]);

    const reloadCallback = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities/${entityId}`)
            .then(({data}) => setEntity(data.data))
            .catch(error => {
                console.error("Entity error", error);
                setError(error.response?.data?.message);
            });
    };

    useEffect(() => {
        if(Error || Error === undefined) { 
            const errorButton = document.getElementById('error');
            if (errorButton) {
                errorButton.click();
                setError('');
            };
        };
    }, [Error]);


    if(user && user.role && user.role === 'volunteer') {
     return <Navigate to={'/dashboard'} />
    };


    return entity?<EntityView reloadCallback={reloadCallback} entity={entity}/> :
    Error || Error === undefined ?
    <div className="error-message">
     <Button id="error" onClick={error}>Error</Button>
    </div>
        :
        <div className="spinner">
            <LoadingOutlined
                style={{
                    fontSize: 52,
                }}
                spin
            />
        </div>
}

export default Entity;
