import { CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Descriptions, Input, Form, Select, Button } from "antd";
import { useTranslation } from "react-i18next";

export const EntityHooks = ({isEditing, entity, dateOptions, setIsEditing, saveLoading, setShowDeleteEntityModal, handleSubmitEntity}) => {

    const { t } = useTranslation();

    const entityInfoDescriptions = () => {
        return isEditing ?
            <>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.nameTitle')}`}>
                    <Form.Item name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.entity.title')}`} rules={[{ required: true }]}>
                    <Form.Item name="type">
                        <Select
                            options={[
                                { value: 'NGO', label: `${t('entity.createModifyEntityModal.entity.ong')}` },
                                { value: 'company', label: `${t('entity.createModifyEntityModal.entity.company')}` },
                                { value: 'political_party', label: `${t('entity.createModifyEntityModal.entity.political_party')}` },
                                { value: 'ORG', label: `${t('entity.createModifyEntityModal.entity.organism_istitution')}` },
                            ]}
                            style={{ minWidth: "10em" }}
                        />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.segmentation.title')}`} rules={[{ required: true }]}>
                    <Form.Item
                        name="segment"
                    >
                        <Select
                            options={[
                                { value: 'Energías Limpias', label: `${t('entity.createModifyEntityModal.segmentation.clean_energies')}` },
                                { value: 'Sector Energía', label: `${t('entity.createModifyEntityModal.segmentation.energy_sector')}` },
                                { value: 'Financieras', label: `${t('entity.createModifyEntityModal.segmentation.financial')}` },
                                { value: 'Energías Sucias', label: `${t('entity.createModifyEntityModal.segmentation.dirty_energies')}` },
                                { value: 'Energía Colaborativa', label: `${t('entity.createModifyEntityModal.segmentation.collaborative_electrical')}` },
                                { value: 'Eléctricas', label: `${t('entity.createModifyEntityModal.segmentation.energy')}` },
                                { value: 'Moda', label: `${t('entity.createModifyEntityModal.segmentation.moda')}` },
                                { value: 'Aseguradoras', label: `${t('entity.createModifyEntityModal.segmentation.insurers')}` },
                                { value: 'Grandes superficies', label: `${t('entity.createModifyEntityModal.segmentation.large_surfaces')}` },
                                { value: 'Ambiental', label: `${t('entity.createModifyEntityModal.segmentation.environmental')}` },
                                { value: 'Política', label: `${t('entity.createModifyEntityModal.segmentation.political')}` },
                                { value: 'Social', label: `${t('entity.createModifyEntityModal.segmentation.social')}` },
                                { value: 'Otras', label: `${t('entity.createModifyEntityModal.segmentation.others')}` },
                            ]}
                            style={{ minWidth: "10em" }}
                        />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.region.title')}`} rules={[{ required: true }]}>
                    <Form.Item name="region">
                        <Select
                            options={[
                                { value: 'international', label: `${t('entity.createModifyEntityModal.region.international')}` },
                                { value: 'europe', label: `${t('entity.createModifyEntityModal.region.europe')}` },
                                { value: 'national', label: `${t('entity.createModifyEntityModal.region.national')}` },
                                { value: 'autonomic', label: `${t('entity.createModifyEntityModal.region.autonomic')}` },
                                { value: 'local', label: `${t('entity.createModifyEntityModal.region.local')}` },
                            ]}
                            style={{ minWidth: "10em" }}
                        />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.addressTitle')}`}>
                    <Form.Item name="address">
                        <Input />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.cifTitle')}`}>
                    <Form.Item name="taxId">
                        <Input />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="URL">
                    <Form.Item name="url">
                        <Input />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.zipCodeTitle')}`}>
                    <Form.Item name="zipCode">
                        <Input />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.phoneTitle')}`}>
                    <Form.Item name="phone">
                        <Input onInput={(e) => {
                            e.preventDefault();
                            const value = e.target.value.replace(/[^\d+]/g, '');
                            e.target.value = value;
                        }} />
                    </Form.Item>
                </Descriptions.Item>
            </> :
            <>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.cifTitle')}`}>{entity.taxId}</Descriptions.Item>
                <Descriptions.Item label="URL"><a href={entity.url}>{entity.name}</a></Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.segmentation.title')}`}>{entity.segment}</Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.region.title')}`}>{t(`region.${entity.region}`)}</Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.addressTitle')}`}>{entity.address}</Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.zipCodeTitle')}`}>{entity.zipCode}</Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.phoneTitle')}`}>{entity.phone}</Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.insertionDate')}`}>{(new Date(entity.createdAt)).toLocaleDateString("es-ES", dateOptions)}</Descriptions.Item>
                <Descriptions.Item label={`${t('entity.createModifyEntityModal.dateLastUpdate')}`}>{(new Date(entity.updatedAt)).toLocaleDateString("es-ES", dateOptions)}</Descriptions.Item>
            </>
    };

    //
    const editButtons = () => {
        return !isEditing ? <Button type="default" onClick={() => setIsEditing(true)}>{t("entity.btnEditEntity")}</Button> :
            <div style={{ display: "flex", gap: "0.2em" }}>
                <Button onClick={() => setIsEditing(false)} disabled={saveLoading} type="dashed" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "red" }} shape="circle" icon={<CloseOutlined />} />
                <Button onClick={() => setShowDeleteEntityModal(true)} disabled={saveLoading} type="default" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "red" }} shape="circle" icon={<DeleteOutlined />} />
                <Button type="primary" loading={saveLoading} shape="circle" icon={<CheckOutlined />} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleSubmitEntity} />
            </div>
    }

    return {
        entityInfoDescriptions,
        editButtons
    }
};

