import {createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import {signInWithGoogle} from "./api/firebase";

/*export interface UserData {
    email: string,
    name: string,
    uid: string,
    authProvider: string,
}

export interface UserState {
    status: 'idle' | 'loading' | 'failed',
    data: UserData | null
}*/

const initialState = {
    status: 'idle',
    data: null
}

export const loginUser = createAsyncThunk(
    'user/loginUser',
    signInWithGoogle
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetStatus: (state)=>{
          state.status='idle';
        },
        logoutUser: (state) => {
            state.status='idle';
            state.data=null;
            localStorage.removeItem('googleAuthToken');
            window.localStorage.removeItem('checkTimeCurrentToken');
            //logout();
        },
        authError: (state) => {
            state.status='failed';
            state.data=null;
            localStorage.removeItem('googleAuthToken');
            window.localStorage.removeItem('checkTimeCurrentToken');

        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(loginUser.pending, (state) => {
            state.status = 'loading';
        })
        builder
        .addCase(loginUser.fulfilled, (state,action) => {
            state.status = 'idle';
            state.data = action.payload;
        })
        builder
        .addCase(loginUser.rejected, (state,action) => {
            //@TODO LOG ERROR
            state.status = 'failed';
        })
    }
})

export const {logoutUser,authError} = userSlice.actions;

export const selectUser = (state) => state.user.data;
export const selectLoginStatus = (state) => state.user.status;
export default userSlice.reducer;
