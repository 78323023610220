import Sider from "antd/es/layout/Sider";
import React, {useState} from "react";
import {
    PieChartOutlined,
    UserOutlined,
    CalendarOutlined,
    ShopOutlined,
    LineChartOutlined, WhatsAppOutlined, FileOutlined
} from '@ant-design/icons';
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../../features/user/userSlice";
import {useTranslation} from "react-i18next";

export const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const user = useSelector(selectUser);
    const { t } = useTranslation();


    return(
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
            <div className="logo"/>
            <Menu mode="inline" theme="dark">
                <div style={{display: "flex", justifyContent: "center"}}>
                    <img src="/greenpeace_green.png" style={{maxWidth: '90%'}}/>
                </div>
                <Menu.Item key={1}>
                    <PieChartOutlined />
                    <span>{t("dashboard.title")}</span>
                    <Link to="/dashboard"/>
                </Menu.Item>
                <Menu.Item key={2}>
                    <UserOutlined />
                    <span>{t("contacts.title")}</span>
                    <Link to="/contacts"/>
                </Menu.Item>
                {user?.role!=='volunteer'&&
                <Menu.Item key={3}>
                    <ShopOutlined />
                    <span>{t("entities")}</span>
                    <Link to="/entities"/>
                </Menu.Item>}
                <Menu.Item key={4}>
                    <CalendarOutlined />
                    <span>{t("events")}</span>
                    <Link to="/events"/>
                </Menu.Item>
                {user?.role!=='volunteer'&&
                    <Menu.Item key={5}>
                        <WhatsAppOutlined /> 
                        <span>{t("interactions.calls")}</span>
                        <Link to="/interactions"/>
                    </Menu.Item>}
                {user?.role!=='volunteer'&&
                    <Menu.Item key={6}>
                        <LineChartOutlined />
                        <span>{t("reports")}</span>
                        <Link to="/reports"/>
                    </Menu.Item>}
                {user?.role!=='volunteer'&&
                    <Menu.Item key={7}>
                        <FileOutlined />
                        <span>{t("documents")}</span>
                        <Link to="/documents"/>
                    </Menu.Item>}
                {user?.role==='admin'&&
                    <Menu.Item key={8}>
                        <UserOutlined />
                        <span>{t("users.users")}</span>
                        <Link to="/users"/>
                    </Menu.Item>
                }
            </Menu>
        </Sider>
    )
}
