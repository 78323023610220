import React, {useEffect} from "react";
import BrowseList from "../../../BrowseList/browseList";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
const EntitiesList = ({search, shouldReload}) => {
    const {t} = useTranslation();
    return <BrowseList
        search={search}
        getName={(item)=>item.name}
        getDescription={(item)=>t(`entity_type.${item.type}`)}
        route="entities"
        getLinkDescription={(item)=>'Ver entidad'}
        onItemCLick={(item)=>`/entities/${item.id}`}
        shouldReload={shouldReload}
    />
}
export default EntitiesList;
