import { Link } from "react-router-dom";
import { t } from "i18next";
import { Breadcrumb, Card } from "antd";


export const CardBreadCrumbs = ({event}) => {

    return (
        <Card style={{ borderRadius: 0 }}>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/events">{t("eventPage.breadCrumbs.events")}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/events/${event.id}`}>{event.summary}</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    </Card>
    );
};