import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { axios } from "../../../../axiosConfig";
import ContactView from "./contact.view";
import { Modal, Button } from 'antd';
import './contact.css';


const Contact = () => {

    const {t} = useTranslation();
    const params = useParams();
    const contactId = params['contactId'];
    const [contact, setContact] = useState();
    const [shouldReload, setShouldReload] = useState(0);
    const [Error, setError] = useState("");

    function error() {
        Modal.error({
            title: `${t('contacts.modal.title')}`,
            content: `${t('contacts.modal.body')}`,
          onOk: () => {
            window.location.href = "/dashboard"; 
        }
        });
      };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts/${contactId}`)
            .then(({ data }) => {
                setContact(data.data);
                if (!data.data) {
                    setError("Error");
                };
            })
            .catch(error => {
                console.error("Contacts error", error);
                setError(error.response?.data?.message);
            });
    }, [contactId, shouldReload]);

    useEffect(() => {
        if(Error || Error === undefined) { 
            const errorButton = document.getElementById('error');
            if (errorButton) {
                errorButton.click();
                setError('');
            };
        };
    }, [Error]);

    return contact ? <ContactView contact={contact} shouldReload={shouldReload} setShouldReload={setShouldReload} /> :
        Error || Error === undefined ?
            <div className="error-message">
                <Button id="error" onClick={error}>Error</Button>
            </div>
            :
            <div className="spinner">
                <LoadingOutlined
                    style={{
                        fontSize: 52,
                    }}
                    spin
                />
            </div>
}

export default Contact;
