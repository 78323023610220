import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Descriptions, Badge, Divider, Modal, Input, Form } from "antd";
import ContactsList from "../contactsList/lists/contacts.list";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { axios } from "../../../../axiosConfig";
import { CardBreadCrumbs, GeneratePositionsList } from "./components";
import AddPositionForm from "./forms/addPosition.form";
import { EntityHooks } from "./hooks/entity-hooks";

const { Search } = Input;

export const EntityView = ({ entity, reloadCallback }) => {
    const [timeoutId, setTimeoutId] = useState();
    const [search, setSearch] = useState('');
    const [shouldReload, setShouldReload] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [showDeleteEntityModal, setShowDeleteEntityModal] = useState(false);
    const [isDeletingEntity, setIsDeletingEntity] = useState(false);
    const [showCreatePositionForm, setShowCreatePositionForm] = useState(false);
    // const [allPositions, setAllPositions] = useState([]); // related with generatePositionsList
    const [contactsList, setContactsList] = useState([]);
    const navigate = useNavigate();
    const formRef = useRef(null);

    const { t } = useTranslation();

    const handleSubmitEntity = () => {
        formRef.current.submit();
    };

    const getAllPositionEntity = (entityId) => {

        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/positions/entity/${entityId}`)
            .then((response) => {
                // setAllPositions(response.data);
                console.log(response.data)
                setShouldReload(shouldReload + 1);

            })
            .catch((err) => { console.log(err) });
    };

    useEffect(() => {
        if (entity && entity.id) {
            getAllPositionEntity(entity.id);
        };
    }, [entity.id]);

    const dateOptions = {
        hour: "numeric",
        minute: "numeric",
        day: "numeric",
        month: "numeric",
        year: "numeric"
    };

    const updateEntityCallback = (values) => {
        setSaveLoading(true);
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities/${entity.id}`, values)
            .then(() => {
                setShouldReload(shouldReload + 1);
                setIsEditing(false);
                reloadCallback();
            })
            .finally(() => setSaveLoading(false))
    };

    const deleteEntityCallback = () => {
        setIsDeletingEntity(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities/${entity.id}`)
            .then(() => navigate('/entities'))
            .finally(() => setIsDeletingEntity(false))
    };

    const handleChange = (event) => {
        // Clear the timeout if it is already set
        if (timeoutId) {
            clearTimeout(timeoutId);
        };

        // Set a new timeout to perform the search after 3 seconds
        setTimeoutId(setTimeout(() => {
            setSearch(event.target.value)
        }, 200));
    };


    const {entityInfoDescriptions, editButtons} = EntityHooks({isEditing, 
                                                               entity, 
                                                               dateOptions, 
                                                               setIsEditing, 
                                                               saveLoading, 
                                                               setShowDeleteEntityModal, 
                                                               handleSubmitEntity
                                                            });

    return (
        <>
            <CardBreadCrumbs entity={entity} />
            <div style={{ padding: '2em' }}>
                <Badge.Ribbon text={t(`entity_type.${entity.type}`)}>
                    <Card title={entity.name}>
                        <Form initialValues={entity} ref={formRef} onFinish={updateEntityCallback}>
                            <Descriptions
                                title={t("entity.createModifyEntityModal.information")}
                                extra={editButtons()}>
                                {entityInfoDescriptions()}
                            </Descriptions>
                        </Form>
                        {/* <GeneratePositionsList allPositions={allPositions} contactsList={contactsList} /> */}
                        <Divider>{t("contacts.title")}</Divider>
                        <Card style={{ margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em', height: '70vh' }}>
                            <div style={{ gap: '2em', display: "flex", flexDirection: "column" }}>
                                <div style={{ display: 'flex', gap: '2em' }}>
                                    <Search placeholder={t("entity.inputSearchTxt")} onChange={handleChange} enterButton={<SearchOutlined style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />} />
                                    {/* <Button onClick={() => setShowCreatePositionForm(true)}
                                    >Crear cargo</Button> //! commented branch-79 07-09-23 - incomplete form */}
                                </div>
                                {/*                            <Modal
                                footer={null}
                                open={showCreateContactModal}
                                onCancel={()=>setShowCreateContactModal(false)}>
                                <CreateContactForm onFinish={onFinish}/>
                            </Modal>*/}
                                <div>
                                    <ContactsList search={search} shouldReload={shouldReload} queryParams={{ entityId: entity.id }}
                                        setContactsList={setContactsList}
                                        contactsList={contactsList} />
                                </div>
                            </div>
                        </Card>
                    </Card>
                </Badge.Ribbon>
            </div>
            <Modal
                title={t("entity.createModifyEntityModal.deleteModal.title")}
                open={showDeleteEntityModal}
                onOk={deleteEntityCallback}
                confirmLoading={isDeletingEntity}
                onCancel={() => setShowDeleteEntityModal(false)}
            >
                {t("entity.createModifyEntityModal.deleteModal.body")}
            </Modal>
            <Modal
                footer={null}
                title={t("entity.createModifyEntityModal.positionModal.title")}
                open={showCreatePositionForm}
                onCancel={() => setShowCreatePositionForm(false)}
            >
                <AddPositionForm onFinish={() => {
                    setShouldReload(shouldReload + 1);
                    setShowCreatePositionForm(false)
                }} entityId={entity.id} />
            </Modal>
        </>
    )
}

export default EntityView;
