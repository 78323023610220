import { Modal } from 'antd';

const ErrorModal = ({ open, onClose, title, errorMessage }) => {
    return (
        <Modal
            open={open}
            title={title}
            onCancel={onClose}
            footer={null}
        >
            <p>{errorMessage}</p>
        </Modal>
    );
};

export default ErrorModal;
