import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { t } from "i18next";
import { List, Button, message, Spin } from 'antd';
import '../documents.css';
import { ArrowLeftOutlined, ArrowRightOutlined, FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
const FolderList = ({ data, onFinishMove, cancel }) => {
    const [folders, setFolders] = useState([]);
    const [currentFolderPath, setCurrentFolderPath] = useState('root');
    const [fileListLoading, setFileListLoading] = useState(false);
    const handleFolderClick = (folder) => {
        setCurrentFolderPath(`${folder.folderPath}`)
    }

    const saveFolder = async (folderPath) => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/file-move`,
                {
                    destFolderPath: `${folderPath || currentFolderPath}/${data.uid}`,
                    fileName: data.folderPath
                }
            );
            onFinishMove();
        } catch (error) {
            message.error('Error moving file');
        }
    }

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                setFileListLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/folders?folderPath=${encodeURIComponent(currentFolderPath)}`);
                const dataResp = response.data.filter(folder => folder.folderPath !== data.name)
                setFileListLoading(false);
                setFolders(dataResp);
            } catch (error) {
                setFileListLoading(false);
                message.error('Error fetching folders');
            }
        };
        fetchFolders();
    }, [currentFolderPath, data ? data.name : null]);

    const listItemStyle = { display: 'flex', gap: '2em', justifyContent: 'space-between', marginBottom: '2em' };
    const spanStyle = { alignItems: 'center' };
    const divStyle = { display: 'flex', alignItems: 'center', gap: '0.5em' };

    return (
        <div>
            {currentFolderPath == 'root' ? null : <Button onClick={() => setCurrentFolderPath(currentFolderPath.substring(0, currentFolderPath.lastIndexOf('/')))} style={{ marginBottom: '1em', padding: '0px 10px 5px' }}><ArrowLeftOutlined /></Button>}
            {fileListLoading ?
                <div className="spin-container"><Spin tip={t("documentsPage.tipFolders")} size="large" /></div> :
                <List
                    dataSource={folders}
                    renderItem={(folder, index) => (
                        <List.Item className='demo-loadmore-list' key={folder.id} style={listItemStyle}>
                            <span style={spanStyle}>
                                <FolderOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
                                {folder.name}
                            </span>
                            <div style={divStyle}>
                                <Button disabled={data.name.split('/').slice(0, -1).join('/') === folder.folderPath} onClick={() => saveFolder(folder.folderPath)} type="primary">{t("documentsPage.modalMoveFileOrFolder")} </Button>
                                <Button onClick={() => handleFolderClick(folder)} style={{ padding: '0px 10px 5px' }}>
                                    <ArrowRightOutlined />
                                    <FolderOpenOutlined />
                                </Button>
                            </div>
                        </List.Item>
                    )
                    }
                />
            }
            < div style={{ display: 'flex', gap: '1em', flexDirection: 'row-reverse' }}>
                <Button type='primary' disabled={data.name === `${currentFolderPath}/${data.uid}`} onClick={() => saveFolder()}>{t("documentsPage.modalMoveFileOrFolder")}</Button>
                <Button onClick={() => (cancel(false))}>{t("documentsPage.btnCanel")}</Button>
            </div >
        </div >
    );
};

FolderList.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        uid: PropTypes.string,
        folderPath: PropTypes.string
    }),
    onFinishMove: PropTypes.func,
    cancel: PropTypes.func,
};

export default FolderList;