import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";

export const EditPositionButton = ({editingPosition, setEditingPosition, saveConctactLoading, setShowCreatePositionModal, savePositionLoading, setIncludesEndsCalendar}) => {

    const { t } = useTranslation();

    return <div style={{ display: "flex", minWidth: "100%", justifyContent: "end" }}>
        {!editingPosition ? <Button onClick={() => setEditingPosition(true)}>{t("contacts.edit_position_button")}</Button> :
            <div style={{ display: "flex", gap: ".2em" }}>
                <Button type="default" disabled={saveConctactLoading} shape="circle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} icon={<PlusOutlined />} onClick={() => setShowCreatePositionModal(true)} />
                <Button type="primary" loading={savePositionLoading} shape="circle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} icon={<CheckOutlined />} onClick={() => (setEditingPosition(false),
                    setIncludesEndsCalendar(false))} />
            </div>}
    </div>
};