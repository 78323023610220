import { Descriptions } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import { useTranslation } from "react-i18next";

export const ContactInfoDescriptions = ({contact, editContactButton}) => {

    const { t } = useTranslation();

    return (
        <Descriptions title={t("contacts.contact_info_title")} extra={editContactButton()}>
            <DescriptionsItem label={t("contacts.forms.update.name")}>{contact.name}</DescriptionsItem>
            <DescriptionsItem label={t("contacts.forms.update.surname")}>{contact.surname ?? ''}</DescriptionsItem>
            <DescriptionsItem label={t("contacts.forms.update.address")}>{contact.address}</DescriptionsItem>
            <DescriptionsItem label={t("contacts.forms.update.zip_code")}>{contact.zipCode}</DescriptionsItem>
            <DescriptionsItem label={t("contacts.forms.update.phone")}>{contact.phone}</DescriptionsItem>
            <DescriptionsItem label={t("contacts.forms.update.email")}>{
                contact.email.includes('.fake') ? "" : <a
                    href={`mailto:${contact.email}`}>{contact.email}</a>
            }</DescriptionsItem>
        </Descriptions>
    )
}