import { Link } from "react-router-dom";
import { t } from "i18next";
import { Breadcrumb, Card } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";


export const CardBredCrumbs = ({interaction}) => {
    return (
        <Card style={{ borderRadius: 0 }}>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/interactions">
                    {t("interactions.title")}
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/interactions/${interaction.id}`} style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                    <span>
                    {`${interaction.contact1.name} ${interaction.contact1.surname ?? ''}`}
                    </span>
                     <WhatsAppOutlined /> 
                     <span>
                        {`${interaction.contact2.name} ${interaction.contact2.surname ?? ''}`}
                     </span>
                </Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    </Card>
    );
};