import React, {useEffect, useState} from 'react';
import {Button, Select} from 'antd';
import {axios} from "../../../axiosConfig";
let timeout;
let currentValue;
const fetch = (value, callback, searchUrl) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    const fake = (searchTerm) => {
        axios.get(searchUrl, {
            params: {
                search: searchTerm,
                limit: 5,
                offset: 0
            },
        })
            .then(({data}) => {
                if (currentValue === value) {
                    const result = data.data;
                    const newVal = result.map((item) => ({
                        value: item.id,
                        text: item.name?(`${item.name} ${item.surname||''} ${item.email?`(${item.email})`:''}`):(item.email||''),
                    }));
                    callback(newVal);
                }
            });
    };
    timeout = setTimeout(()=>fake(value), 200);
};

const SearchInput = (props) => {
    const [data, setData] = useState(props.initialValue?[props.initialValue]:[]);
    const [value, setValue] = useState(props.initialValue?.value);
    const [showCreateEntityButton, setShowCreateEntityButton] = useState(false);

    useEffect(()=>{console.log('value changed:', value)},
        [value])
    const fetchDefaultValue = (defaultValueUrl) => {
        axios.get(defaultValueUrl, {
        })
            .then(({data}) => {
                setData([{
                    value: data.data.id,
                    text: data.data.name?(`${data.data.name} ${data.data.surname||''} ${data.data.email?`(${data.data.email})`:''}`):(data.data.email||''),
                }])
                setValue(data.data.id);
                props.onChange(data.data.id);
            });
    }

    useEffect(()=>{
        props.initialValueUrl&&fetchDefaultValue(props.initialValueUrl);
        },
        [])

    const handleSearch = (newValue) => {
        if (newValue) {
            fetch(newValue, (newData) => {
                setData(newData);
                setShowCreateEntityButton(newData.length === 0);
            }, props.url);
        } else {
            setData([]);
            setShowCreateEntityButton(false);
        }
    };

    const handleChange = (newValue) => {
        props.onChange(newValue);
        setValue(newValue);
    };

    return (
        <Select
            showSearch
            value={value}
            mode={props.mode}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={showCreateEntityButton ? <Button onClick={props.createButtonCallback} type="primary" style={{width: "100%"}}>{props.createButtonName}</Button> : null}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.text,
            }))}
        />
    );
};

export default SearchInput;
