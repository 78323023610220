import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {hotjar} from "react-hotjar";
const container = document.getElementById('root');
const root = createRoot(container);

const hotjarSiteId = parseInt(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = parseInt(process.env.REACT_APP_HOTJAR_VERSION);


hotjar.initialize(hotjarSiteId, hotjarVersion);


i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        lng: 'es',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {

});

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <GoogleOAuthProvider clientId="508697786408-6699pq3j0v2e9dup49i38bnb3ji72nve.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
