import React, { createContext, useState } from 'react';
import ErrorModal from "../../components/Modal/ErrorModal/errorModal";

export const ErrorModalContext = createContext();

const ErrorModalProvider = ({ children }) => {
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const showErrorModal = (title, message) => {
        setErrorTitle(title);
        setErrorMessage(message);
        setErrorModalOpen(true);
    };

    const hideErrorModal = () => {
        setErrorModalOpen(false);
    };

    return (
        <ErrorModalContext.Provider value={showErrorModal}>
            {children}
            <ErrorModal
                open={errorModalOpen}
                title={errorTitle}
                onClose={hideErrorModal}
                errorMessage={errorMessage}
            />
        </ErrorModalContext.Provider>
    );
};

export default ErrorModalProvider;
