import { t } from "i18next";
import { Descriptions } from "antd";
import { formatDate } from "../../../../helpers";

export const EventDataDescriptions = ({event, editEventButton}) => {

    const typePlaceholder = event?.type?.toLowerCase();

    return (

        <Descriptions title={t("eventPage.descriptionPage")} extra={editEventButton()}>
            <Descriptions.Item label={t("eventPage.eventData.startDate")}>{formatDate(new Date(event.startsAt))}</Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventData.endDate")}>{formatDate(new Date(event.endsAt))}</Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventData.region")}>{ t(`eventPage.regionInput.${event.region}`)  }</Descriptions.Item>
            <Descriptions.Item label={t("type.type")}>{ t(`type.${typePlaceholder}`)  }</Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventData.documentUrl")}>
                <a href={event.documentUrl} rel="noreferrer noopener" target="_blank">{event.documentUrl}</a>
            </Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventData.office")}>{ event.office && t(`officeInput.${event.office}`) }</Descriptions.Item>
       </Descriptions>
        );
    };
