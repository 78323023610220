import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Popconfirm, Timeline, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { formatDate } from "../../../../helpers";

export const GeneratePositionsList = ({ contact, editingPosition, setShowUpdatePositionModal, savePositionLoading, deletePosition }) => {

    const { t } = useTranslation();
    const { Title } = Typography;

    return (
        <>
            <Timeline>
                {contact.positions.map((position, index) =>
                    <Timeline.Item key={index}>
                        <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
                            {editingPosition &&
                                <Button
                                    onClick={() => setShowUpdatePositionModal(
                                        {
                                            ...position,
                                            startsAt: moment(position.startsAt),
                                            endsAt: position.endsAt ? moment(position.endsAt) : null,
                                            updatedAt: moment(position.updatedAt),
                                            createdAt: moment(position.createdAt),
                                        }
                                    )}
                                    type="default"
                                    loading={savePositionLoading}
                                    shape="circle"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    icon={<EditOutlined />}
                                />
                            }
                            {editingPosition &&
                                <Popconfirm
                                    title={t("contacts.delete_position_confirm")}
                                    okText={t("contacts.delete_positon_confirm")}
                                    cancelText={t("contacts.delete_position_cancel")}
                                    onConfirm={() => deletePosition(position)}
                                >
                                    <Button
                                        type="default"
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }}
                                        loading={savePositionLoading}
                                        shape="circle"
                                        icon={<DeleteOutlined />} />
                                </Popconfirm>
                            }
                            <div>
                                <Title level={3}>{position && position?.title}</Title>
                                <Link to={`/entities/${position.entity.id}`}>{position.entity.name} {position.political_party && `(${position.political_party})`}</Link>&nbsp;
                                <span> {position.startsAt && formatDate(new Date(position.startsAt), '', false)} - {
                                    position.endsAt ? (formatDate(new Date(position.endsAt), '', false)) : ` ${t("contacts.position_til_now")}`}
                                </span>
                                {position?.email && <>
                                    &nbsp; | <span style={{ color: "rgba(0,40,78)" }}>{position?.email}</span>
                                </>}
                                {position?.political_party && <>
                                    &nbsp; | <span style={{ color: "rgba(0,40,78)" }}>{position?.political_party}</span>
                                </>}
                                {position?.phone && <>
                                    &nbsp; | <span style={{ color: "rgba(0,40,78)" }}>{position?.phone}</span>
                                </>}
                                {position?.zipCode && <>
                                    &nbsp; | <span style={{ color: "rgba(0,40,78)" }}>{position?.zipCode}</span>
                                </>}
                                {position?.address && <>
                                    &nbsp; | <span style={{ color: "rgba(0,40,78)" }}>{position?.address}</span>
                                </>}

                            </div>
                        </div>
                    </Timeline.Item>)}
            </Timeline>
        </>
    )
};