import { t } from "i18next"
import { WhatsAppOutlined } from "@ant-design/icons"
import { Descriptions, Form } from "antd"


export const CardTitle = ({interaction, formRef, editButtons}) => {


    return (
    <div >
        <div style={{display: 'flex', gap: '0.5em' }}>
         <span>
            {`${interaction.contact1.name} ${interaction.contact1.surname ?? ''}`}
         </span> 
         <WhatsAppOutlined /> 
         <span>
            {`${interaction.contact2.name} ${interaction.contact2.surname ?? ''}`}
         </span>
        </div>
        <Form initialValues={interaction} ref={formRef}>
            <Descriptions
                title={t("interactions.information")}
                extra={editButtons()}>
            </Descriptions>
        </Form>
    </div>
    )
}