import {useState} from "react";
import { t } from "i18next";
import {Button, Form, Input} from "antd";
import {axios} from "../../../../../axiosConfig";

const InviteUserForm = ({onFinish}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitForm = (values) => {
        setIsSubmitting(true)
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`,{...values})
            .then(()=>{
                setIsSubmitting(false);
                onFinish(values);
            });
    };

    return <Form onFinish={submitForm}>
        <Form.Item label={t("usersPage.form.email")} name="email" style={{paddingTop: '3em',paddingBottom: '1em' }} rules={[{ required: true }]}>
            <Input />
        </Form.Item>
        <Form.Item label={t("usersPage.form.name")} name="name" style={{paddingBottom: '1em'}} rules={[{ required: true }]}>
            <Input/>
        </Form.Item>
        <Form.Item label={t("usersPage.form.surnames")} style={{paddingBottom: '1em'}} name="surname">
            <Input/>
        </Form.Item>
        <Form.Item label={t("usersPage.form.address")}  style={{paddingBottom: '1em'}} name="address">
            <Input/>
        </Form.Item>
        <Form.Item label={t("usersPage.form.postalCode")}  style={{paddingBottom: '1em'}} name="zipCode">
            <Input onInput={(e) => {
            e.preventDefault();
            const value = e.target.value.replace(/[^\d]/g, '');
            e.target.value = value;
        }} />
        </Form.Item>
        <Form.Item label={t("usersPage.form.phone")}  style={{paddingBottom: '1em'}} type="number" name="phone">
            <Input  onInput={(e) => {
            e.preventDefault();
            const value = e.target.value.replace(/[^\d+]/g, '');
            e.target.value = value;
        }}  />
        </Form.Item>
        <Form.Item style={{ display: 'flex', justifyContent: 'center'}}>
            <Button type="primary" htmlType="submit"  style={{paddingBottom: '1em'}} loading={isSubmitting}>
                {t("usersPage.form.btnText")}
            </Button>
        </Form.Item>
    </Form>
}

export default InviteUserForm;
