import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Card, Upload, message, Modal, Tooltip, Spin, Button } from "antd";
import './documents.css';
import { axios } from "../../../../axiosConfig";
import Meta from "antd/es/card/Meta";
import {
    DeleteOutlined,
    DownloadOutlined,
    InboxOutlined,
    FilePdfTwoTone,
    FileExcelTwoTone,
    FileWordTwoTone,
    FilePptTwoTone,
    FileTextTwoTone, FileImageTwoTone, FileZipTwoTone, FileUnknownTwoTone, setTwoToneColor,
    FolderOutlined,
    FileOutlined,
    DragOutlined
} from '@ant-design/icons';
import { useSelector } from "react-redux";
import { selectUser } from "../../../../features/user/userSlice";
import FolderForm from "./form-folder/folder.form"
import AppBreadcrumb from "./breadcrumb/breadcrumb"
import FolderList from "./folder-list/folderList";
const { Dragger } = Upload;

const getFileIcon = (file) => {
    const style = { fontSize: '2em' };
    const extension = file.name.split('.').pop().toLowerCase();
    setTwoToneColor('green');
    switch (extension) {
        case 'pdf':
            return <FilePdfTwoTone twoToneColor="#ff4d4f" style={style} />;
        case 'xls':
        case 'xlsx':
            return <FileExcelTwoTone twoToneColor="#00a854" style={style} />;
        case 'doc':
        case 'docx':
            return <FileWordTwoTone twoToneColor="#1890ff" style={style} />;
        case 'ppt':
        case 'pptx':
            return <FilePptTwoTone twoToneColor="#eb2f96" style={style} />;
        case 'txt':
            return <FileTextTwoTone twoToneColor="#999999" style={style} />;
        case 'jpg':
        case 'jpeg':
        case 'png':
            return <FileImageTwoTone twoToneColor="#722ed1" style={style} />;
        case 'zip':
        case 'rar':
        case '7z':
            return <FileZipTwoTone twoToneColor="#faad14" style={style} />;
        case 'svg':
            return <FileOutlined twoToneColor="#722ed1" style={style} />;
        default:
            if (Number(file.size) > 0) {
                return <FileUnknownTwoTone style={style} />;
            } else {
                return <FolderOutlined style={style} />;
            }

    }

};

const handleDownloadClick = ({ uid }) => {
    // Replace with your own file download logic
    const downloadUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/files/${uid}`;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', downloadUrl, true);
    xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem('googleAuthToken')}`);
    xhr.responseType = 'blob';

    xhr.onload = () => {
        const url = window.URL.createObjectURL(xhr.response);
        const a = document.createElement('a');
        a.href = url;
        a.download = uid;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };
    xhr.send();
}
const Documents = () => {
    const [fileList, setFilelist] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState();
    const [showDeleteLoading, setShowDeleteLoading] = useState(false);
    const [fileListLoading, setFileListLoading] = useState(false);

    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showMoveFileOrFolderModal, setShowMoveFileOrFolderModal] = useState(false);
    const [currentFolderPath, setCurrentFolderPath] = useState('root');
    const [breadcrumbItems, setBreadcrumbItems] = useState([{ label: 'Inicio', folder: 'root' }]);
    const [file, setFile] = useState(null);
    const user = useSelector(selectUser);

    const props = {
        name: 'file',
        multiple: false,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('googleAuthToken')}`
        },
        action: `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/files?folderPath=${encodeURIComponent(currentFolderPath)}`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                getFiles()
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleDeleteClick = (file) => {
        setShowDeleteModal(file);
    }

    const handleDeleteConfirm = async () => {
        setShowDeleteLoading(true);
        if (Number(showDeleteModal?.size) == 0) {
            const encodedId = encodeURIComponent(showDeleteModal?.folderPath);
            await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/folder/${encodedId}`)
                .then(setTimeout(() => {
                    setShowDeleteModal(false)
                    setShowDeleteLoading(false)
                    message.success(`${t("documentsPage.msgSuccessFolder")}`)
                    getFiles()
                }, 1000))
                .catch(() => { setShowDeleteLoading(false); message.error(`${t("documentsPage.msgErrorDeleteFolder")}`) })

        } else {
            const encodedId = encodeURIComponent(showDeleteModal?.folderPath);
            await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/files/${encodedId}`)
                .then(() => getFiles())
                .then(() => setShowDeleteLoading(false), setShowDeleteModal(false), message.success(`${t("documentsPage.msgSuccess")}`))
                .catch(() => { setShowDeleteLoading(false); message.error(`${t("documentsPage.msgErrorDelete")}`) })
        }
    }

    const getFiles = () => {
        setFileListLoading(true);
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/files?folderPath=${currentFolderPath}`)
            .then(({ data }) => data.map(file => {
                return {
                    uid: file.folderPath.split('/').pop(),
                    name: file.name,
                    size: file.size,
                    status: 'done',
                    folderPath: file.folderPath,
                    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/files/${file.name}`,
                }
            }).sort((a, b) => a.size - b.size))
            .then(files => {
                setFilelist(files);
                setFileListLoading(false);
            }).catch(() => {
                setFileListLoading(false);
                message.error(`${t("documentsPage.msgErrorLoading")}`);
            })
    }
    const searchFolderClick = (event) => {
        if (Number(event.size) === 0) {
            setCurrentFolderPath(event.folderPath)
            const index = breadcrumbItems.findIndex(item => item.folder === event.name)
            if (index === -1) {
                setBreadcrumbItems([...breadcrumbItems, { label: event.uid, folder: event.name }])
            } else {
                setBreadcrumbItems(breadcrumbItems.slice(0, index + 1))
            }
        }
    }

    const onFinish = (file) => {
        message.success(`${file.name} folder uploaded successfully.`);
        setShowCreateFolderModal(false);
        getFiles()
    }

    const onFinishMove = () => {
        message.success(`${t("documentsPage.msgSuccessMove")}`);
        setShowMoveFileOrFolderModal(false);
        getFiles();
    }

    const changeFolder = (event) => {
        setCurrentFolderPath(event.folder)
        const index = breadcrumbItems.findIndex(item => item.folder === event.folder)
        setBreadcrumbItems(breadcrumbItems.slice(0, index + 1))
    }

    useEffect(() => {
        getFiles();
    },
        [currentFolderPath]
    )

    return (
        <>
            <Card style={{ borderRadius: 0 }} title={t("documentsPage.title")}>
                {t("documentsPage.descriptionPage")}
            </Card>
            <Card style={{ margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em', height: 'auto' }}>
                <div style={{ display: 'flex', gap: '2em', justifyContent: 'space-between', marginBottom: '2em' }}>
                    <AppBreadcrumb changeFolder={changeFolder} breadcrumbItems={breadcrumbItems} />
                    <Button
                        onClick={() => setShowCreateFolderModal(true)}
                    >{t("documentsPage.forms.createFolder.btnCreate")}</Button>
                </div>
                <div style={{ gap: '2em', display: "flex", flexDirection: "column" }}>
                    {user?.role === 'admin' && <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t("documentsPage.descriptionAction")}</p>
                    </Dragger>}
                    <div style={{ display: "grid", gap: '1em', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))' }}>
                        {fileListLoading ?
                            <div className="spin-container"><Spin tip={t("documentsPage.tip")} size="large" /></div> :
                            fileList.map(file =>
                                <div key={file.folderPath}>
                                    <Tooltip title={file.uid}>
                                        <Card
                                            className="folder-card"
                                            onClick={() => user?.role !== 'admin' && handleDownloadClick(file)}
                                            actions={user?.role === 'admin' && [
                                                Number(file.size) == 0 ? '' : <DownloadOutlined key="setting" onClick={() => handleDownloadClick(file)} style={{ fontSize: '1.2em' }} />,
                                                <DeleteOutlined key={file.folderPath} onClick={() => handleDeleteClick(file)} className="delete-outlined" />,
                                                <DragOutlined key={file.folderPath} onClick={() => { setFile(file); setShowMoveFileOrFolderModal(true); }} className="move-outlined" />,
                                            ]}
                                        >
                                            <Meta
                                                onClick={() => user?.role === 'admin' && searchFolderClick(file)}
                                                style={{ display: 'flex', alignItems: 'center' }}
                                                avatar={getFileIcon(file)}
                                                title={file.uid}
                                            />
                                        </Card>
                                    </Tooltip>
                                </div>)
                        }
                    </div>
                </div>
            </Card >
            <Modal
                title={t("documentsPage.modalTitleFolder")}
                footer={null}
                open={showCreateFolderModal}
                onCancel={() => setShowCreateFolderModal(false)}>
                <FolderForm currentFolderPath={currentFolderPath} onFinish={onFinish} />
            </Modal>
            <Modal
                title={`${t("documentsPage.modalMoveFileOrFolder")} "${file?.uid}"`}
                footer={null}
                open={showMoveFileOrFolderModal}
                onCancel={() => setShowMoveFileOrFolderModal(false)}>
                <FolderList data={file} onFinishMove={onFinishMove} cancel={(e) => setShowMoveFileOrFolderModal(e)} />
            </Modal>
            <Modal
                title={Number(showDeleteModal?.size) == 0 ? t("documentsPage.modalDeleteFolder.title") : t("documentsPage.modalDeleteFile.title")}
                open={showDeleteModal}
                onOk={handleDeleteConfirm}
                confirmLoading={showDeleteLoading}
                onCancel={() => setShowDeleteModal(null)}
                okButtonProps={{ style: { backgroundColor: '#f54242' } }}
            >
                <p>{Number(showDeleteModal?.size) == 0 ? `${t("documentsPage.modalDeleteFolder.body")}  ${showDeleteModal?.uid}?` : `${t("documentsPage.modalDeleteFile.body")}  ${showDeleteModal?.uid}?`}</p>
            </Modal>
        </>
    );
}

export default Documents;
