import {
    Avatar,
    Button,
    Collapse,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    Segmented,
    Tag,
    Timeline
} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {axios} from "../../../../../axiosConfig";
import {formatDate, sortByDate} from "../../../../helpers/date";
import './interactions.section.css';
import {
    CalendarOutlined,
    CheckOutlined, ClockCircleOutlined, EditOutlined,
    PhoneOutlined,
    PlusOutlined, SettingOutlined,
    UserOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import CreateInteractionForm from "../forms/createInteraction.form";
import CreateEventForm from "../../eventsList/forms/createEvent.form";
import {Link} from "react-router-dom";
import moment from "moment/moment";

const getCalendarEvents = async(contactId) => {
    const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events`,{
        params: {
            limit: 50,
            offset: 0,
            contactId
        }

    });
    return data.data
}

const getCallInteractions = async(contactId) => {
    const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions`,{
        params: {
            limit: 50,
            offset: 0,
            contactId
        }

    });
    return data.data
}

const getInteractionIcon = (type) => {
    return {
        'calendar_event': <CalendarOutlined/>,
        'phone':<PhoneOutlined/>,
        'in_person': <WhatsAppOutlined/>
    }[type]
}

const generateInteractionDescriptions = (interaction, t) => {
    switch (interaction.type) {
        case 'phone':
            return <Descriptions>
                <Descriptions.Item label={t("interactions.forms.display_data.type.label")}>{t(`interactions.forms.display_data.type.${interaction.type}`)}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.phone")}>{interaction.phone}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.startsAt")}>{formatDate(interaction.startsAt)}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.createdBy")}><Link to={`/contacts/${interaction.creator.id}`}>{interaction.creator.name?(`${interaction.creator.name} ${interaction.creator.surname||''} ${interaction.creator.email?`(${interaction.creator.email})`:''}`):(interaction.creator.email||'')}</Link></Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.notes")}>{interaction.notes}</Descriptions.Item>
            </Descriptions>
        case 'in_person':
            return <Descriptions>
                <Descriptions.Item label={t("interactions.forms.display_data.type.label")}>{t(`interactions.forms.display_data.type.${interaction.type}`)}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.phone")}>{interaction.phone}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.startsAt")}>{formatDate(interaction.startsAt)}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.createdBy")}><Link to={`/contacts/${interaction.creator.id}`}>{interaction.creator.name?(`${interaction.creator.name} ${interaction.creator.surname||''} ${interaction.creator.email?`(${interaction.creator.email})`:''}`):(interaction.creator.email||'')}</Link></Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.notes")}>{interaction.notes}</Descriptions.Item>
            </Descriptions>
        case 'calendar_event':
            return <Descriptions>
                <Descriptions.Item label={t("interactions.forms.display_data.start")}>{formatDate(new Date(interaction.startsAt))}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.end")}>{formatDate(new Date(interaction.startsAt))}</Descriptions.Item>
            </Descriptions>
    }
}

const generateInteractionDescriptionsUpdateForm = (interaction, t) => {
    switch (interaction.type) {
        case 'phone':
            return <Form><Descriptions>
                <Form.Item><Descriptions.Item label={t("interactions.forms.display_data.type.label")}>{t(`interactions.forms.display_data.type.${interaction.type}`)}</Descriptions.Item></Form.Item>
                <Form.Item><Descriptions.Item label={t("interactions.forms.display_data.phone")}>{interaction.phone}</Descriptions.Item></Form.Item>
                <Form.Item><Descriptions.Item label={t("interactions.forms.display_data.startsAt")}>{formatDate(interaction.startsAt)}</Descriptions.Item></Form.Item>
                <Form.Item><Descriptions.Item label={t("interactions.forms.display_data.createdBy")}><Link to={`/contacts/${interaction.creator.id}`}>{interaction.creator.name?(`${interaction.creator.name} ${interaction.creator.surname||''} ${interaction.creator.email?`(${interaction.creator.email})`:''}`):(interaction.creator.email||'')}</Link></Descriptions.Item></Form.Item>
                <Form.Item><Descriptions.Item label={t("interactions.forms.display_data.notes")}>{interaction.notes}</Descriptions.Item></Form.Item>
            </Descriptions><Button>Update</Button></Form>
        case 'in_person':
            return <Descriptions>
                <Descriptions.Item label={t("interactions.forms.display_data.type.label")}>{t(`interactions.forms.display_data.type.${interaction.type}`)}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.phone")}>{interaction.phone}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.startsAt")}>{formatDate(interaction.startsAt)}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.createdBy")}><Link to={`/contacts/${interaction.creator.id}`}>{interaction.creator.name?(`${interaction.creator.name} ${interaction.creator.surname||''} ${interaction.creator.email?`(${interaction.creator.email})`:''}`):(interaction.creator.email||'')}</Link></Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.notes")}>{interaction.notes}</Descriptions.Item>
            </Descriptions>
        case 'calendar_event':
            return <Descriptions>
                <Descriptions.Item label={t("interactions.forms.display_data.start")}>{formatDate(new Date(interaction.startsAt))}</Descriptions.Item>
                <Descriptions.Item label={t("interactions.forms.display_data.end")}>{formatDate(new Date(interaction.startsAt))}</Descriptions.Item>
            </Descriptions>
    }
}

const InteractionsSection = ({contact, showModal, setShowModal}) => {

    const getCreateInteractionModalContent = (type, onFinish) => {
        return {
            'calendar_event': <CreateEventForm onFinish={onFinish}/>,
            'call':<CreateInteractionForm onFinish={onFinish} contact={contact} type="phone"/>,
            'conversation':<CreateInteractionForm onFinish={onFinish} contact={contact} type="in_person"/>,
        }[type]
    }

    const getUpdateInteractionModalContent = (onFinish, event) => {
        const eventInitialValuesInteraction = {
            ...event,
            startsAt: moment(event.startsAt)
        }
        return {
            'phone':<CreateInteractionForm onFinish={onFinish} contact={contact} type="phone" initialValues={eventInitialValuesInteraction}/>,
            'in_person':<CreateInteractionForm onFinish={onFinish} contact={contact} type="in_person" initialValues={eventInitialValuesInteraction}/>,
        }[event.type]
    }

    const onFinishCreateInteraction = () => {
        fetchEvents();
        setShowModal({...showModal, createInteraction: false});
    }

    const onFinishUpdateInteraction = () => {
        fetchEvents();
        setShowModal({...showModal, updateInteraction: false});
    }

    const {t} = useTranslation();
    const [contactEvents,setContactEvents] = useState([]);
    const [createInteractionModalContent,setCreateInteractionModalContent] = useState();
    const [updateInteractionModalContent,setUpdateInteractionModalContent] = useState();

    useEffect(()=>{
        contact&&setCreateInteractionModalContent(getCreateInteractionModalContent('calendar_event',onFinishCreateInteraction))
        },
        [contact]);

    const fetchEvents = () => {
        Promise.all([
            getCalendarEvents(contact.id),
            getCallInteractions(contact.id)
        ])
            .then(([calendarEvents, callInteractions]) => {
                    return calendarEvents.map(event=>{
                        return {
                            ...event,
                            type: 'calendar_event'
                        }
                    })
                        .concat(
                            callInteractions
                        )
                }
            )
            .then(events=>sortByDate(events,'startsAt'))
            .then(events=>setContactEvents(events))
    }


    useEffect(()=> {
        fetchEvents();
    },[contact]);

    return <>
        <Divider>{t("interactions.title")}</Divider>
        <Timeline mode="left">
            {contactEvents.map((event,key)=>
                <Timeline.Item dot={getInteractionIcon(event.type)} key={key}>
                    <Collapse collapsible="icon" expandIcon={()=>false}>
                        <Collapse.Panel
                            extra={<><Tag icon={<ClockCircleOutlined />} color="default">
                                {formatDate(event.startsAt)}
                            </Tag>
                                </>
                            }
                            header={<div>
                                {<a href={`/events/${event.id}`}>{event?.summary}</a> || event?.notes}
                        </div>} key={key}>
                            <div style={{marginLeft:"5em"}}>

                            </div>
                            {generateInteractionDescriptions(event, t)}
                        </Collapse.Panel>
                    </Collapse>
                </Timeline.Item>)
            }
        </Timeline>
        <Modal
            footer={null}
            open={showModal.createInteraction}
            onCancel={()=>setShowModal({...showModal,createInteraction: false})}>
            <div style={{width: '100%', display: 'flex', padding:'1em', justifyContent:'center'}}>
            <Segmented
                onChange={(value)=>setCreateInteractionModalContent(getCreateInteractionModalContent(value, onFinishCreateInteraction,contact.id))}
                options={[
                    {
                        label: (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                <Avatar icon={<CalendarOutlined/>}/>
                                <span>{t("interactions.forms.display_data.type.meeting")}</span>
                            </div>
                        ),
                        value: 'calendar_event',
                    },
                    {
                        label: (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                <Avatar style={{ backgroundColor: '#f56a00' }} icon={<PhoneOutlined/>}></Avatar>
                                <span>{t("interactions.forms.display_data.type.phone")}</span>
                            </div>
                        ),
                        value: 'call',
                    },
                    {
                        label: (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<WhatsAppOutlined />} />
                                <span>{t("interactions.forms.display_data.type.in_person")}</span>
                            </div>
                        ),
                        value: 'conversation',
                    },
                ]}
            />
            </div>
            {createInteractionModalContent}
        </Modal>

        <Modal
            footer={null}
            open={showModal.updateInteraction}
            onCancel={()=>setShowModal({...showModal,updateInteraction: false})}>
            <div style={{width: '100%', display: 'flex',padding:'1em',justifyContent:'center'}}>
                <Segmented
                    options={[{
                        'event':{
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar icon={<CalendarOutlined/>}/>
                                    <div>{t("interactions.forms.display_data.type.meeting")}</div>
                                </div>
                            ),
                            value: 'calendar_event',
                        },
                        'phone':{
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar style={{ backgroundColor: '#f56a00' }} icon={<PhoneOutlined/>}></Avatar>
                                    <div>{t("interactions.forms.display_data.type.phone")}</div>
                                </div>
                            ),
                            value: 'call',
                        },
                        'in_person':{
                            label: (
                                <div style={{ padding: 4 }}>
                                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<WhatsAppOutlined />} />
                                    <div>{t("interactions.forms.display_data.type.in_person")}</div>
                                </div>
                            ),
                            value: 'conversation',
                        },
                    }[showModal.updateInteraction.type]]}
                />
            </div>
            {updateInteractionModalContent}
        </Modal>
    </>
}

export default InteractionsSection;
