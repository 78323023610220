import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { axios } from "../../../../../axiosConfig";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";

const FolderForm = ({ currentFolderPath, onFinish }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    const submitForm = (values) => {
        setIsSubmitting(true)
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/folder`, { ...values, folderPath: currentFolderPath })
            .then(({ data }) => {
                setIsSubmitting(false);
                onFinish(values);
                return data.id
            }).catch(() => {
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 1000);
            })
    }

    return <Form onFinish={submitForm} style={{ marginTop: "2em" }}>
        <Form.Item style={{ paddingBottom: "1em" }} label={t('documentsPage.forms.createFolder.name')} name="name" rules={[{ required: true }]}>
            <Input />
        </Form.Item>
        <Form.Item style={{ paddingBottom: "1em" }} wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {t("documentsPage.forms.createFolder.btnCreate")}
            </Button>
        </Form.Item>
    </Form>
}

FolderForm.propTypes = {
    currentFolderPath: propTypes.string.isRequired,
    onFinish: propTypes.func.isRequired
}
export default FolderForm;
