import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getCalendarEvents from "./api/backend/calendarService";

const initialState = {
    events: null,
    status: null
}

export const getEvents = createAsyncThunk(
    'calendar/getEvents',
    getCalendarEvents
)

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetCalendarStatus: (state)=>{
            state.events=null
        },
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getEvents.pending, (state) => {
            state.status = 'loading';
        })
        builder
        .addCase(getEvents.fulfilled, (state,action) => {
            state.status = 'idle';
            state.events = action.payload;
        })
        builder
        .addCase(getEvents.rejected, (state,action) => {
            //@TODO LOG ERROR
            state.status = 'failed';
        })
    }
})

export const {resetCalendarStatus} = calendarSlice.actions;

export const selectCalendar = (state) => state.calendar.events;
export const selectCalendarStatus = (state) => state.calendar.status;
export default calendarSlice.reducer;
