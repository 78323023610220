import React from "react";
import {Link} from "react-router-dom";
import { t } from "i18next";
import BrowseList from "../../../BrowseList/browseList";

const UsersList = ({search,shouldReload,queryParams}) => {
    return <BrowseList
        search={search}
        getName={(item)=>`${item.name} ${item.surname||''}`}
        getDescription={(item) => (
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.email.includes('.fake')?null:item.email}
                {item.positions.filter(position => !position.endsAt).map(position => (
                    <> | {position.title} {t("in")} <Link to={`/entities/${position.entity.id}`}>{position.entity.name}</Link></>
                ))}
            </div>
        )
        }
        route="contacts" shouldReload={shouldReload}
        getLinkDescription={(item)=>item.role==='pending'? t('usersPage.validate') : t('usersPage.deleteUser')}
        onItemCLick={(item)=>`/contacts/${item.id}`}
        queryParams={queryParams}
    />
}
export default UsersList;
