import { useTranslation } from "react-i18next";
import { CloseOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";


export const ContactHooks = ({editingContact, setEditingContact, setShowDeleteContactModal, saveConctactLoading, handleSubmitContact, setShowModal, showModal}) => {

    const { t } = useTranslation();


    const editContactButton = () => {
        return !editingContact ? <Button type="default" onClick={() => setEditingContact(true)}>{t("contacts.forms.update.edit")}</Button> :
            <div style={{ display: "flex", gap: ".2em" }}>
                <Button onClick={() => setEditingContact(false)} disabled={saveConctactLoading} type="dashed" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }} shape="circle" icon={<CloseOutlined />} />
                <Button onClick={() => setShowDeleteContactModal(true)} disabled={saveConctactLoading} type="default" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }} shape="circle" icon={<DeleteOutlined />} />
                <Button type="primary" loading={saveConctactLoading} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="circle" icon={<CheckOutlined />} onClick={handleSubmitContact} />
            </div>
    };


    const showFromInteraction = () => {
        return <div style={{ display: "flex", minWidth: "100%", justifyContent: "end" }}>
            {/*
            {!editingInteractions ? <Button onClick={()=>setEditingInteractions(true)}>{t("contacts.edit_position_button")}</Button>:  */}
            <div style={{ display: "flex", gap: ".2em", paddingTop: "2em" }}>
                <Button type="default" onClick={() => setShowModal({ ...showModal, createInteraction: true })}>
                    {t("interactions.call_interactions.create")}
                </Button>
            </div>
        </div>
    };

 return {
    editContactButton,
    showFromInteraction
 };

};