import React, {useEffect, useReducer, useState} from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {Avatar, Divider, List, Skeleton} from "antd";
import {axios} from "../../../axiosConfig";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../../features/user/userSlice";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const limit = 10;

function reducer(state, action) {
    switch (action.type) {
        case 'increment':
            return {count: state.count + limit};
        case 'decrement':
            return {count: Math.max(state.count - limit, 0)};
        case 'reset':
            return {count: 0}
        default:
            throw new Error();
    }
}
const BrowseList = ({search, getIcon, getName, getDescription, route, shouldReload, getLinkDescription, linkDescriptionColor, onItemCLick, queryParams}) => {
    const [data,setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, dispatchOffset] = useReducer(reducer, {search}, (init)=>{return {count: 0}});
    const [isEnd, setIsEnd] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    let currentSearchValue=true;

    useEffect(()=>{
        if ((search||search==='') && search!==currentSearchValue) {
            currentSearchValue=search;
            dispatchOffset({type: 'reset'})
            setData([]);
        }
    },[search])

    useEffect(()=>{
        if(shouldReload>0) {
            currentSearchValue=search;
            dispatchOffset({type: 'reset'})
            setData([]);
        }
    },[shouldReload]);

    useEffect(()=>{
            if (data.length === 0 && !loading) {
                console.log('data',data);
                loadMoreData(false, 0);
            }
        },
        [data]);

    const loadMoreData = (reset=true,offsetValue=null) => {

        if (data && data.length > 0 && reset) {
            return;
        }
        if (loading) {
            return;
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/${route}?limit=${limit}&offset=${offsetValue!==null?offsetValue:offset.count}${search ? '&search=' + search : ''}`,
                {
                    params: queryParams
                })
            .then(({data}) => {
                if(data.data.length>0) setData(state => [...state, ...data.data]);
                setLoading(false);
                dispatchOffset({type: 'increment'});
                if (data.data?.length === 0) setIsEnd(true);
                else setIsEnd(false);
                // console.log("Data", data.data[1]);

            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                dispatch(logoutUser());
            });
    };

    return (
        <div
            id="scrollableDiv"
            style={{
                height: 400,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
        >
            <InfiniteScroll
                dataLength={data?.length ?? 0}
                next={()=>loadMoreData(false)}
                hasMore={!isEnd}
                loader={loading && <Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>{t('no_more_items')}</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={data}
                    renderItem={(item, index) => (
                       

                            <List.Item key={item?.id ?? index}
                            >
                                <List.Item.Meta
                                    avatar={getIcon&&getIcon(item)?getIcon(item):item?.picture?<Avatar src={item?.picture} />:null}
                                    title={getName(item)}
                                    description={getDescription(item)}
                                />
                                <Link to={onItemCLick(item)}>{getLinkDescription(item)}</Link>
                            </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>
    );

}

export default BrowseList;
