const formatDate = (date, separator = ' - ', includeTime=true) => {
    if (typeof date === 'string' || date instanceof String) date = new Date(date);
    const dateString = date.toLocaleDateString("es-ES", {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const timeString = date.toLocaleTimeString("es-ES", {
        hour: '2-digit',
        minute: '2-digit',
    });
    return `${dateString}${includeTime?`${separator}${timeString}`:''}`;
}

const lastUpdatedDifference = (pastDate) => {
    // Get the current time in milliseconds
    const currentTime = new Date().getTime();
    // Get the number of milliseconds since the Unix epoch for the past date
    const pastTime = pastDate.getTime();
    // Calculate the difference in milliseconds
    const timeDifference = currentTime - pastTime;
    // Convert the difference in milliseconds to days by dividing by the number of milliseconds in a day
    return Math.round(timeDifference / (1000 * 60 * 60 * 24));
}

const sortByDate = (arr, key, order = 'desc') => {
    return arr.sort((a, b) => {
        if (order === 'asc') {
            return new Date(a[key]).getTime() - new Date(b[key]).getTime();
        } else {
            return new Date(b[key]).getTime() - new Date(a[key]).getTime();
        }
    });
}

export {formatDate, lastUpdatedDifference, sortByDate};
