import React from "react";
import { t } from "i18next";
import { Input, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";


export const AddNewEmail = ({tags, setTags}) => {

    const [inputVisible, setInputVisible] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const inputRef = React.useRef(null);

    
    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value.toLowerCase().trim());
      };

      const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
          setTags([...tags, inputValue]);
        }
        inputRef.current.blur();

          setInputVisible(false);
          setInputValue('');
      };


      React.useEffect(() => {
        if (inputVisible) {
          inputRef.current?.focus();
        }

      }, [inputVisible]);
    

      let widthInput = inputValue.length * 4 + 80;


    return (
        <>
         {inputVisible ? (
        <Input
        className="TagEmailInput"
        style={{width: `${widthInput}px`}}
        ref={inputRef}
        type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag onClick={showInput} className="TagEmailBtn">
         <PlusOutlined /> &nbsp; {t("contacts.forms.position.newEmail")}
        </Tag>
      )}
        </>
    )
};