import {Button, Divider, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {axios} from "../../../../../../axiosConfig";
import {formatDate} from "../../../../../helpers/date";
import {useNavigate} from "react-router-dom";

const SuggestionsSection = ({contact}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [selectedRowKey, setSelectedRowKey] = useState();
    const renderFunction = (data)=>data?.updated?<b style={{color: 'red'}}>{data?.value}</b>:<span style={{color: 'gray'}}>{data?.value}</span>;
    const dateRenderFunction = (data)=>data?.updated?<b style={{color: 'red'}}>{data?.value}</b>:<span style={{color: 'gray'}}>{formatDate(data?.value)}</span>;
    const contactOwnerRenderFunction = (data) => <a>{data.name}</a>
    const [dataSource, setDataSource] = useState([]);

    const applySuggestion = () => {
        const contactOwnerId = dataSource.find(value=>value.key===selectedRowKey[0]).key;
        const contactMatchId = dataSource.find(value=>value.key===selectedRowKey[0]).matchId;
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/suggestions/contacts/${contact.id}?targetId=${contactMatchId}`)
            .then(()=>navigate('/contacts'))
    }

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/suggestions/contacts/${contact.id}`)
            .then(({data})=>setDataSource(data.data))
        },
        [])

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            render: dateRenderFunction
        },
        {
            title: 'Contacto de',
            dataIndex: 'contactOwner',
            key: 'contactOwner',
            render: contactOwnerRenderFunction
        },
        {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email',
            render: renderFunction
        },{
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: renderFunction
        },
        {
            title: 'Apellidos',
            dataIndex: 'surname',
            key: 'surname',
            render: renderFunction
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            render: renderFunction
        },
        {
            title: 'Código postal',
            dataIndex: 'zipCode',
            key: 'zipCode',
            render: renderFunction
        },
        {
            title: 'Teléfono',
            dataIndex: 'phone',
            key: 'phone',
            render: renderFunction
        },
    ];

    return <>
        <Divider>Sugerencias</Divider>
        <div style={{display: "flex", minWidth: "100%", justifyContent: "end", paddingBottom: '2em'}}>
            <Button disabled={!selectedRowKey} onClick={applySuggestion}>Aplicar sugerencia</Button>
        </div>
        <Table dataSource={dataSource} className="custom-radio-table"  columns={columns} rowSelection={{type:'radio',onChange:setSelectedRowKey}}/>
    </>
}

export default SuggestionsSection;
