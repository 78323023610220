import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import {axios} from "../../../../axiosConfig";
import EventView from "./event.view";
import { Modal, Button } from 'antd';
import './event.styles.css';

const Event = () => {

    const {t} = useTranslation();
    const params = useParams();
    const eventId = params['eventId'];
    const [event,setEvent] = useState();
    const [shouldReload, setShouldReload] = useState(0);
    const [Error, setError] = useState("");
 
    function error() {
        Modal.error({
            title: `${t('eventsModal.title')}`,
            content: `${t('eventsModal.body')}`,
          onOk: () => {
            window.location.href = "/dashboard"; 
        }
        });
      };


    useEffect(()=> {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events/${eventId}`)
                .then(({data}) => {
                    setEvent(data.data)
                    if (!data.data) {
                        setError("Error");
                    };})
                .catch(error => {
                    console.error("Contacts error", error);
                    setError(error.response?.data?.message);
                });
        },
        [eventId, shouldReload]);

        useEffect(() => {
            if(Error || Error === undefined) { 
                const errorButton = document.getElementById('error');
                if (errorButton) {
                    errorButton.click();
                    setError('');
                };
            };
        }, [Error]);
        console.log("EVENT", event);
    

    return event?<EventView event={event} shouldReload={shouldReload} setShouldReload={setShouldReload}/>:
    Error || Error === undefined ?
    <div className="error-message">
        <Button id="error" onClick={error}>Error</Button>
    </div>
    :
    <div className="spinner">
        <LoadingOutlined
            style={{
                fontSize: 52,
            }}
            spin
        />
    </div>
}

export default Event;
