import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Card } from "antd";

export const CardBreadCrumbs = ({entity}) => {

    const { t } = useTranslation();

    return (
    <Card style={{ borderRadius: 0 }}>
     <Breadcrumb>
        <Breadcrumb.Item>
            <Link to="/entities">{t('entities')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to={`/entities/${entity.id}`}>{entity.name}</Link>
        </Breadcrumb.Item>
     </Breadcrumb>
    </Card>
    );
};