import { Button, DatePicker, Form, Input } from "antd";
import { useState } from "react";
import { axios } from "../../../../../axiosConfig";
import SearchInput from "../../../searchInput/searchInput";
import { useTranslation } from "react-i18next";
import { displayName } from "react-quill";

const AddPositionForm = ({ entityId, onFinish }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const createPosition = (values) => {
        // Set the isSubmitting flag to true
        setIsSubmitting(true);

        // Submit the form values to the /v1/events route
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/positions`, {
            ...values,
            entityId
        })
            .then(() => {
                // Set the isSubmitting flag to false and call the onFinish callback
                setIsSubmitting(false);
                onFinish(values);
            });
    }


    return <Form onFinish={createPosition}>
        <Form.Item
            style={{ paddingBottom: '1em' }}
            label={t("contacts.title")}
            name="contactId">
            <SearchInput url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`} mode="single" />
        </Form.Item>
        <Form.Item
            style={{ paddingBottom: '1em' }}
            label={t("contacts.forms.position.title")}
            name="title"
        >
            <Input />
        </Form.Item>
        <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
            style={{ paddingBottom: '1em', textAlign: 'right' }}
            label={t("contacts.forms.position.starts_at")}
            name="startsAt"
        >
            <DatePicker format="DD-MM-YYYY" />
        </Form.Item>

        <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
            style={{ paddingBottom: '1em', textAlign: 'right' }} 
            label={t("contacts.forms.position.ends_at")}
            name="endsAt"
        >
            <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {t("contacts.create_position_button")}
            </Button>
        </Form.Item>
    </Form>
}

export default AddPositionForm;
