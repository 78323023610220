import { useNavigate } from "react-router-dom";
import { t } from "i18next"
import { Button } from "antd"
import { CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import { axios } from "../../../../../axiosConfig";


export const InteractionHooks = ({isEditing, setIsEditing, setShowDeleteInteractionModal, saveLoading, interaction, handleSubmitInteraction, setIsDeletingInteraction}) => {

    const navigate = useNavigate();


    const editButtons = () => {
        return (
            <>
                {!isEditing ?
                    <Button type="default" onClick={() => setIsEditing(true)}>
                        {t("interactions.edit")}
                    </Button>
                    :
                    <div style={{ display: "flex", gap: "0.2em" }}>
                        <Button onClick={() => setIsEditing(false)} disabled={saveLoading} type="dashed" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "red" }} shape="circle" icon={<CloseOutlined />} />
                        <Button onClick={() => setShowDeleteInteractionModal(true)} disabled={saveLoading} type="default" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "red" }} shape="circle" icon={<DeleteOutlined />} />
                        <Button type="primary" loading={saveLoading} shape="circle" icon={<CheckOutlined />} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleSubmitInteraction} />
                    </div>
                }
            </>
        )
    };

    const deleteInteractionCallback = () => {
        setIsDeletingInteraction(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interaction.id}`)
            .then(() => navigate('/interactions'))
            .finally(() => setIsDeletingInteraction(false))
    };


    return {
        editButtons,
        deleteInteractionCallback
    }
};