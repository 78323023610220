import { initializeApp } from "firebase/app";
import {
    getAuth,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    where,
    addDoc,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { axios } from "../../../axiosConfig";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const signInWithGoogle = (userToken) => {
    try {
        //const credential = GoogleAuthProvider.credential(userToken);
        //const res = await signInWithCredential(auth, credential)
        //const user = res.user;
        //const q = query(collection(db, "users"), where("uid", "==", user.uid));
        //const {docs} = await getDocs(q);
        window.localStorage.setItem('googleAuthToken', userToken);
        window.localStorage.setItem('checkTimeCurrentToken', Date.now())
        return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/user`)
            .then(({ data }) => {
                return {
                    name: data.data.name,
                    authProvider: "google",
                    email: data.data.email,
                    avatar: data.data.picture,
                    role: data.data.role,
                    userToken,
                    id: data.data.id
                }
            })
    } catch (e) {
        console.error(e)
        localStorage.removeItem('googleAuthToken');
        window.localStorage.removeItem('checkTimeCurrentToken');
    }

};

const logout = () => {
    signOut(auth)
        .then(r => console.log(r))
        .catch(error => console.log(error));
};

export {
    auth,
    db,
    signInWithGoogle,
    logout
};
