import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Card } from "antd";


export const CardBreadCrumb = ({contact}) => {

    const {t} = useTranslation();

    return (
        <Card style={{ borderRadius: 0 }}>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/contacts">{t("contacts.title")}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/contacts/${contact.id}`}>{contact.email && `${contact.name} ${contact.surname ?? ''}`}</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    </Card>
    )
};