import { Link } from "react-router-dom";
import { t } from "i18next";
import { Button, Form, Input, Select } from "antd";
import SearchInput from "../../../searchInput/searchInput";
import { axios } from "../../../../../axiosConfig";
import { UserAddOutlined } from "@ant-design/icons";


export const UpdateInteractionForm = ({initialValues, interaction, shouldReload, setShouldReload}) => {

    const handleTypeChange = (type) => {
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interaction.id}/`, { type })
            .then(() => setShouldReload(shouldReload + 1))
    };

    const handleContact1Change = (contact1Id) => {
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interaction.id}/`, { contact1Id })
            .then(() => setShouldReload(shouldReload + 1))
    };
    
    const handleContact2Change = (contact2Id) => {
        console.log('newValue2', contact2Id);
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interaction.id}/`, { contact2Id })
            .then(() => setShouldReload(shouldReload + 1))
    };
    
    const handleNotesChange = (notes) => {
        console.log('newValueNotes', notes);
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${interaction.id}/`, { notes })
            .then(() => setShouldReload(shouldReload + 1))
    };


    return (
        <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5em' }}
         initialValues={initialValues} >
        <Form.Item
            label={t("interactions.updateInteraction.type")}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            name="type"
            rules={[{ required: true }]}
        >
            <Select
                placeholder={initialValues.type ? initialValues.type : t("interactions.updateForm.typePlaceholder")}
                options={[
                    { value: 'in_person', label: `${t("interactions.forms.display_data.type.in_person")}` },
                    { value: 'phone', label: `${t("interactions.forms.display_data.type.phone")}` }
                ]}
                style={{ minWidth: "10em" }}
                onChange={handleTypeChange}
            />
        </Form.Item>
        <Form.Item
            label={t("interactions.updateInteraction.contact1")}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <SearchInput
                url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`}
                mode="single"
                placeholder={initialValues.contact1 ? initialValues.contact1 : t("interactions.updateForm.contact1")}
                style={{ width: '30em' }}
                onChange={handleContact1Change}
            /> &nbsp;

            <Button
                type="primary"
                htmlType="submit"
                icon={<UserAddOutlined />} >
                {t("interactions.updateForm.btnAddAssistant")}
            </Button>
            <span style={{ marginLeft: '0.5em' }}>
             {t("or")} 
             <Link to="/contacts">{t("interactions.updateForm.link_create_contact")}</Link>
            </span>
        </Form.Item>
        <Form.Item
            label={t("interactions.updateInteraction.contact2")}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <SearchInput
                url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`}
                mode="single"
                placeholder={initialValues.contact2 ? initialValues.contact2 : t("interactions.updateForm.contact2")}
                style={{ width: '30em' }}
                onChange={handleContact2Change}
            /> &nbsp;
            <Button
                type="primary"
                htmlType="submit"
                icon={<UserAddOutlined />}
                onClick={(event) => {
                    handleContact2Change();
                }} >
                {t("interactions.updateForm.btnAddAssistant")}
            </Button>
            <span style={{ marginLeft: '0.5em' }}>
                {t("or")} 
                <Link to="/contacts">
                    {t("interactions.updateForm.link_create_contact")}
                </Link>
            </span>
         </Form.Item>
        <Form.Item name="notes" label={t("interactions.updateInteraction.notes")}>
            <Input.TextArea
            placeholder={t("interactions.updateForm.notePlaceholder")}
                onChange={(e) => handleNotesChange(e.target.value)}
                name="notes"
                value={initialValues.notes}
            />
        </Form.Item>
    </Form>
    )
}