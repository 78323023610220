import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../../../features/user/userSlice";
import { axios } from "../../../../../axiosConfig";
import SearchInput from "../../../searchInput/searchInput";
import CreateContactForm from "../../contactsList/forms/createContact.form";
import './creteInteraction.form.css';

const CreateInteractionForm = ({ onFinish, initialValues, contact, type }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);

    const { t } = useTranslation();
    const user = useSelector(selectUser);
    useEffect(() => { console.log('initialValues', initialValues) }, [initialValues]);
    // define the form layout and validation rules
    const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
        style: {
            display: "flex",
            flexDirection: "column",
            gap: "1em"
        }
    };
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
    };

    const createInteractions = (values) => {
        // Set the isSubmitting flag to true
        setIsSubmitting(true);

        // Submit the form values to the /v1/call_interactions route
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions`, {
            ...values,
            startsAt: startsAt.toISOString(),
            type
        })
            .then(() => {
                // Set the isSubmitting flag to false and call the onFinish callback
                setIsSubmitting(false);
                onFinish(values);
            });
    }
    const updateInteractions = (values) => {
        // Set the isSubmitting flag to true
        setIsSubmitting(true);

        // Submit the form values to the /v1/call_interactions route
        axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/call-interactions/${initialValues.id}`, {
            ...values,
            startsAt: startsAt.toISOString(),
            type
        })
            .then(() => {
                // Set the isSubmitting flag to false and call the onFinish callback
                setIsSubmitting(false);
                onFinish(values);
            });
    }
    const [startsAt, setStartsAt] = useState(new Date());
    const [contact1Id, setContact1Id] = useState();
    const [contact2Id, setContact2Id] = useState();
    const [callNumber, setCallNumber] = useState();
    const [notes, setNotes] = useState();

    return (
        <>
    <Form
        onFinish={initialValues ? updateInteractions : createInteractions} {...formLayout} validateMessages={validateMessages} initialValues={initialValues}>
        <Form.Item
            label={t("interactions.call_interactions.forms.position.startsAt")}
            name="startsAt"
            rules={[{ required: true }]}
        >
            <DatePicker showTime format="DD-MM-YYYY HH:mm"
                  value={startsAt}
                  onChange={(date) => setStartsAt(date)}
                // disabledDate={(current) => {
                //     return current && current < moment().startOf('day');
                // }}
                 />
        </Form.Item>
        <Form.Item
            label={t("interactions.call_interactions.forms.position.contact1Id")}
            initialValue={contact1Id}
            name="contact1Id"
            rules={[{ required: true }]}
        >
            <SearchInput
                url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`}
                initialValueUrl={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts/${user.id}`}
                createButtonName="Crear contacto"
                createButtonCallback={() => setShowCreateContactModal(true)} />
        </Form.Item>
        <Form.Item
            label={t("interactions.call_interactions.forms.position.contact2Id")}
            name="contact2Id"
            initialValue={contact2Id}
            rules={[{ required: true }]}
        >
            <SearchInput
                url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`}
                initialValueUrl={contact && `${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts/${contact.id}`}
                createButtonName="Crear contacto"
                createButtonCallback={() => setShowCreateContactModal(true)}

            />
        </Form.Item>
        <Form.Item
            label={t("interactions.call_interactions.forms.position.callNumber")}
            name="callNumber"
            rules={[{ required: true }]}
            initialValue={contact && contact.phone}
        >
            <Input
                style={{ minWidth: '10em' }}

                onInput={(e) => {
                    e.preventDefault();
                    const value = e.target.value.replace(/[^\d+]/g, '');
                    e.target.value = value;
                }}
            />
        </Form.Item>
        <Form.Item
            label={t("interactions.call_interactions.forms.position.notes")}
            name="notes"
        >
            <Input.TextArea />
        </Form.Item>
        <Form.Item  style={{display: 'flex', justifyContent: 'center'}}>
            <Button type="primary" htmlType="submit" loading={isSubmitting} >
                {initialValues ? t("interactions.call_interactions.update") : t("interactions.call_interactions.create")}
            </Button>
        </Form.Item>
    </Form>
       <Modal
            title="Crear contacto"
            footer={null}
            open={showCreateContactModal}
            onCancel={() => setShowCreateContactModal(false)}>
            <CreateContactForm onFinish={() => setShowCreateContactModal(false)} />
        </Modal>
    </>
)
}

export default CreateInteractionForm;
