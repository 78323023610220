import {Avatar, Badge, Dropdown, Layout, Switch} from "antd";
import './header.css';
import {useAppDispatch} from "../../hooks";
import {logoutUser, selectUser} from "../../../features/user/userSlice";
import {useSelector} from "react-redux";
import {useContext} from "react";
import {ThemeContext} from "../../../App";
import {BulbOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const Header = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const toggleTheme = useContext(ThemeContext);
    const { t } = useTranslation();

    const rightMenuItems = {
        items: [
            {
                key: '1',
                label:
                    <a>
                        {t('logout')}
                    </a>
            },
            ],
        onClick: ({key}) => {
            dispatch(logoutUser())
        }
    }
    return(
        <Layout.Header className="header-container">
            <div></div>
            <div className="header-right-container">
                    {/*<Switch
                        defaultChecked
                        onChange={toggleTheme}
                        checkedChildren={<BulbOutlined />}
                    />*/}
            <Dropdown
                trigger={["click"]}
                menu={rightMenuItems}
                placement="bottomRight"
            >
                <div onClick={e => e.preventDefault()} className="header-menu-selector">
                    <a className="ant-menu-item ant-menu-dark">{user?.name}</a>
                    {/*<Badge count={5} size="small">*/}
                    <Avatar size="large" src={user?.avatar} className="header-menu-avatar"/>
                    {/*</Badge>*/}
                </div>
            </Dropdown>
            </div>
        </Layout.Header>
    )
}

export default Header;
