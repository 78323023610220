import {axios} from "../../../../axiosConfig";

const getCalendarEvents = async() => {
    const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events`,{
        params: {
            limit: 200,
            offset: 0
        }

    });
    return data.data
}

export default getCalendarEvents;
